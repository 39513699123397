import React from "react";
import classNames from "classnames";
import Logo from "../logo/Logo";
import User from "./dropdown/user/User";
import Notification from "./dropdown/notification/Notification";
import Toggle from "../sidebar/Toggle";

import {useTheme, useThemeUpdate} from '../provider/Theme';

const Header = ({fixed, className, ...props}) => {
    const theme = useTheme();
    const themeUpdate = useThemeUpdate();
    //const [onHover, setOnHover] = useState(false);
    const headerClass = classNames({
        "nk-header": true,
        "nk-header-fixed": fixed,
        [`is-light`]: theme.header === "white",
        [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
        [`${className}`]: className,
    });

    return (
        <div className={headerClass}>
            <div className="container-lg wide-xl">
                <div className="nk-header-wrap">
                    <div className="nk-header-brand">
                        <Logo/>
                    </div>
                    <div className="nk-header-tools">
                        <ul className="nk-quick-nav">
                            <li className="notification-dropdown" onClick={themeUpdate.sidebarHide}>
                                <Notification/>
                            </li>
                            <li className="user-dropdown" onClick={themeUpdate.sidebarHide}>
                                <User/>
                            </li>
                            <li className="d-lg-none">
                                <Toggle icon="menu" className="toggle nk-quick-nav-icon me-n1"
                                        click={themeUpdate.sidebarVisibility}/>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Header;
