import {
    createContext, 
    useCallback, 
    useContext, 
    useEffect, 
    useState
} from "react";
import {toast} from "react-toastify";
import {toastOptions} from "../utils/Presets";
import {useFormik} from "formik";
import httpClient from "../utils/HttpClient";
const CompanyContext = createContext({});

export const CompanyProvider = ({companyId, children}) => {
    const [companyData, setCompanyData] = useState(null)
    const [isLoadingCompany, setLoadingCompany] = useState(false);

    const {
        setValues,
    } = useFormik({
        initialValues: {
            taxNumber: '',
            fullName: '',
            type: '',
            businessName: '',
            foundationDate: '',
            entityId: '',
            holderType: '',
            holderName: '',
            holderTaxNumber: '',
            holderIdentity: '',
            holderGender: '',
            holderNationality: '',
            holderBirthDate: '',
            holderBirthCity: '',
            holderBirthState: '',
            holderMotherFullName: '',
            holderFatherFullName: '',
            holderPhone: '',
            holderEmail: '',
            projectId: '',
            dateContract: '',
            effectiveDate: '',
            dueDate: '',
            addressZipcode: '',
            addressStreet: '',
            addressNumber: '',
            addressComplement: '',
            addressNeighborhood: '',
            addressCity: '',
            addressState: '',
            contactPhone: '',
            contactPhoneAlt: '',
            contactEmail: '',
            sendAnalisys: false,
            fileCNPJ: [],
            fileSocialContract: [],
            fileProofResidence: [],
            fileGFIP: [],
            fileIdentity: [],
            fileDriveLicense: [],
            sendCard: false
        },
    })

    const fetchCompany = useCallback(async () => {
        try {
            if (!companyId) return;
            setLoadingCompany(true);
            const res = await httpClient.get(`/credenciamento/empresa/${companyId}`);
            const {data} = res.data;
            setCompanyData(data);
            const {
                id,
                TaxNumber,
                Name,
                Type,
                BusinessName,
                FoundationDate,
                EntityId,
                ProjectId,
                DateContract,
                EffectiveDate,
                DueDate,
                Address,
                Holders,
                Documents,
                ContractStatus,
                SendCard
            } = {...data};
            const {
                Zipcode,
                Street,
                Number,
                Complement,
                Region,
                City,
                State,
                Phone,
                PhoneAlt,
                Email
            } = {...Address};
            const Holder = Holders && Holders.length > 0 ? {...Holders[0]} : {};
            const FileSocialContract = [];
            const FileGFIP = [];
            const FileDriveLicense = [];
            for (const index in Documents) {
                const {Src, Type, TypeDescription} = {...Documents[index]};
                switch (Type) {
                    case 5:
                        FileSocialContract.push({preview: Src, name: TypeDescription});
                        break;
                    case 1006:
                        FileGFIP.push({preview: Src, name: TypeDescription});
                        break;
                    default:
                }
            }
            if (Holders) {
                for (const index in Holder.Documents) {
                    const {Src, Type, TypeDescription} = {...Holder.Documents[index]};
                    switch (Type) {
                        case 3:
                            FileDriveLicense.push({preview: Src, name: TypeDescription});
                            break;
                        default:
                    }
                }
            }
            await setValues({
                'id': id,
                'sendAnalisys': ContractStatus > 0,
                'taxNumber': TaxNumber,
                'fullName': Name,
                'type': Type,
                'businessName': BusinessName,
                'foundationDate': FoundationDate,
                'entityId': EntityId,
                'holderType': Holder?.Type,
                'holderName': Holder?.FullName,
                'holderTaxNumber': Holder?.TaxNumber,
                'holderIdentity': Holder?.Identity,
                'holderGender': Holder?.Gender,
                'holderNationality': Holder?.Nationality,
                'holderBirthDate': Holder?.BirthDate,
                'holderBirthCity': Holder?.BirthCity,
                'holderBirthState': Holder?.BirthState,
                'holderMotherFullName': Holder?.MotherFullName,
                'holderFatherFullName': Holder?.FatherFullName,
                'projectId': ProjectId,
                'dateContract': DateContract,
                'effectiveDate': EffectiveDate,
                'dueDate': DueDate,
                'addressZipcode': Zipcode,
                'addressStreet': Street,
                'addressNumber': Number,
                'addressComplement': Complement,
                'addressNeighborhood': Region,
                'addressCity': City,
                'addressState': State,
                'contactPhone': Phone,
                'contactPhoneAlt': PhoneAlt,
                'contactEmail': Email,
                'sendCard': SendCard,
                'fileSocialContract': FileSocialContract,
                'fileGFIP': FileGFIP,
                'fileDriveLicense': FileDriveLicense
            });
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
        }
        finally {
            setLoadingCompany(false);
        }
    }, [companyId, setValues]);

    useEffect(() => {
        fetchCompany().then();
    }, [fetchCompany]);

    return (
        <CompanyContext.Provider value={{companyData, isLoadingCompany}}>
            {children}
        </CompanyContext.Provider>
    );
};

export const useCompanyContext = () => {
    return useContext(CompanyContext);
}
