import React, {useLayoutEffect} from "react";
import {Routes, Route, useLocation} from "react-router-dom";

// PAGES LAYOUTS
import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";

// EXTERNALS PAGES
import Login from "../pages/auth/Login";

// INTERNAL PAGES
import Dashboard from "../pages/Dashboard";
import CompaniesList from '../pages/companies/List';
import CompaniesForm from '../pages/companies/components/Form';
import PersonsList from '../pages/persons/List';
import PersonsForm from '../pages/persons/components/Form';
import NotificationList from '../pages/notifications/List';
import NotificationDetails from '../pages/notifications/Details';
import ProfileLayout from '../pages/profile/Layout';
import ProfilePersonal from '../pages/profile/Personal';
import ProfileSecurity from '../pages/profile/Security';
import ProfileActivity from "../pages/profile/Activity";

// ERROR PAGES
import Error404Classic from "../pages/error/404-classic";
import Register from "../pages/auth/Register";
import PersonFormContainer from "../pages/persons/PersonFormContainer";
import CompanyFormContainer from "../pages/companies/CompanyFormContainer";

const Pages = () => {
    const location = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <Routes>
            <Route path={'/'} element={<Layout/>}>
                <Route index element={<Dashboard/>}/>
                <Route path={'/companies'}>
                    <Route path={''} element={<CompaniesList/>} index/>
                    <Route path={'new'} element={<CompaniesForm/>}/>
                    <Route path={'data/:companyId'} element={<CompanyFormContainer/>}/>
                </Route>
                <Route path={'/persons'}>
                    <Route path={''} element={<PersonsList/>} index/>
                    <Route path={'new'} element={<PersonsForm/>}/>
                    <Route path={'data/:personId'} element={<PersonFormContainer/>}/>
                </Route>
                <Route path={'/notifications'}>
                    <Route path={''} element={<NotificationList/>} index/>
                    <Route path={'details/:notificationId'} element={<NotificationDetails/>}/>
                </Route>
                <Route path={'/profile'} element={<ProfileLayout/>}>
                    <Route path={''} element={<ProfilePersonal/>} index/>
                    <Route path={'security'} element={<ProfileSecurity/>}/>
                    <Route path={'activity'} element={<ProfileActivity/>}/>
                </Route>
            </Route>
            <Route path={`/`} element={<LayoutNoSidebar/>}>
                <Route index path="login" element={<Login/>}></Route>
                <Route path="recover" element={<Register/>}></Route>
                <Route path="register" element={<Register/>}></Route>
                <Route path="*" element={<Error404Classic/>}></Route>
            </Route>
        </Routes>
    );
};
export default Pages;
