import React, {useCallback, useEffect, useState} from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Button,
    Icon
} from "../../components/Component";
import {toast} from "react-toastify";
import {toastOptions} from "../../utils/Presets";
import {useNavigate, useParams} from "react-router-dom";
import {Spinner} from "reactstrap";
import {parseToDateBR} from "../../utils/Commons";
import httpClient from "../../utils/HttpClient";
import useMediaQuery from "../../hooks/useMediaQuery";
import Swal from "sweetalert2";

const Details = () => {
    const breakpoint = useMediaQuery();
    const [toggleMenu, setToggleMenu] = useState(false);

    const navigate = useNavigate();
    const {notificationId} = useParams();
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState(false);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const resp = await httpClient.get(`extras/notificacao/${notificationId}`);
            const {data} = resp.data;
            setData(data);
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
            navigate('/notifications');
        } finally {
            setLoading(false);
        }
    }, [navigate, notificationId]);

    const _handleDelete = async () => {
        try {
            setLoading(true);
            const confirm = await Swal.fire({
                title: 'Apagar notificação',
                text: 'Deseja realmente apagar esta notificação?',
                showCancelButton: true,
                cancelButtonText: 'Não',
                confirmButtonText: 'Sim'
            });
            if (confirm.isConfirmed) {
                await httpClient.delete(`extras/notificacao/${notificationId}`);
                navigate('/notifications');
            }
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData().then();
    }, [fetchData]);

    return (
        <React.Fragment>
            <Head title="Notificações"/>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>{data.Title}</BlockTitle>
                            <BlockDes className={'text-soft'}>Recebido em {parseToDateBR(data.SentAt, true)}</BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className={'toggle-wrap nk-block-tools-toggle'}>
                                <Button
                                    className={`btn-icon btn-trigger toggle-expand me-n1 ${breakpoint === 'xs' ? 'active' : ''}`}
                                    onClick={() => setToggleMenu(!toggleMenu)}>
                                    <Icon name="menu-alt-r"></Icon>
                                </Button>
                                <div className="toggle-expand-content"
                                     style={{display: toggleMenu ? "block" : "none"}}>
                                    <ul className="nk-block-tools g-1">
                                        <li className="nk-block-tools-opt">
                                            <Button
                                                type={'button'}
                                                className={'btn-dim'}
                                                color={'danger'}
                                                disabled={isLoading}
                                                onClick={_handleDelete}>
                                                {isLoading ? <Spinner size={'sm'}/> : <Icon name={'trash'}/>}
                                                <span>EXCLUIR</span>
                                            </Button>
                                        </li>
                                        <li className={'nk-block-tool-opt'}>
                                            <Button
                                                type={'button'}
                                                color="light"
                                                outline
                                                onClick={() => navigate(-1)}
                                            >
                                                <Icon name="arrow-left"></Icon>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block>
                    <div dangerouslySetInnerHTML={{__html: data.Content}}/>
                </Block>
            </Content>
        </React.Fragment>
    );
};

export default Details;
