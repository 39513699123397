import {
    Block,
    BlockContent,
    BlockDes,
    BlockHead,
    BlockTitle,
    Button,
    Icon,
    OutlinedInput,
    PreviewCard
} from "../../components/Component";
import {Form, Spinner, Alert} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {toastOptions} from "../../utils/Presets";
import {toast} from "react-toastify";
import React, {useState} from "react";
import Logo from "../../images/logo2x.png";
import LogoDark from "../../images/logo2x.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import httpClient from "../../utils/HttpClient";

const Register = () => {
        const [isLoading, setLoading] = useState(false);
        const [showPwd, setShowPwd] = useState(false);
        const [tokenInput, setTokenInput] = useState(false);
        const [errRegister, setErrRegister] = useState(null);
        const navigate = useNavigate();

        const {
            handleSubmit, handleChange, handleBlur, touched, errors, values, isValid
        } = useFormik({
            initialValues: {
                login: '',
                newPassword: '',
                token: ''
            }, onSubmit: async v => {
                try {
                    setLoading(true);
                    const endpoint = 'vendas/usuario/senha/criar';
                    const d = {
                        login: v.login
                    };
                    if (!tokenInput) {
                        d.newPassword = v.newPassword;
                        setTokenInput(true);
                    } else {
                        d.token = v.token;
                    }
                    await httpClient.post(endpoint, d);
                    if (tokenInput) {
                        toast.success('Senha confirmada com sucesso', {
                            ...toastOptions,
                        });
                        navigate('/');
                    }
                } catch (err) {
                    if (err.response) {
                        const {errors} = {...err.response.data};
                        Object.entries(errors).forEach(([, value]) => {
                            setErrRegister(value);
                        })
                    }
                } finally {
                    setLoading(false);
                }
            }
        });

        return <React.Fragment>
            <Head title="Login"/>
            <Block className="nk-block-middle nk-auth-body  wide-xs">
                <div className="brand-logo pb-4 text-center">
                    <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
                        <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo"/>
                        <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark"/>
                    </Link>
                </div>
                <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
                    <BlockHead>
                        <BlockContent>
                            <BlockTitle tag="h4">Criar senha</BlockTitle>
                            <BlockDes>
                                <p>Criar senha para autenticar no Portal de Vendas.</p>
                            </BlockDes>
                        </BlockContent>
                    </BlockHead>
                    {errRegister && (
                        <div className="mb-3">
                            <Alert color="danger" className="alert-icon">
                                <Icon name="alert-circle"/> {errRegister}
                            </Alert>
                        </div>)
                    }
                    <Form className="is-alter" onSubmit={handleSubmit}>
                        {!tokenInput && (
                            <>
                                <div className={'form-group'}>
                                    <OutlinedInput type={'email'}
                                                   name={'login'}
                                                   label={'Informe seu e-mail'}
                                                   size={'xl'}
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   value={values.login}
                                                   isInvalid={errors.login && touched.login}
                                                   error={errors.login}/>
                                </div>
                                <div className={'form-group'}>
                                    <OutlinedInput type={showPwd ? 'text' : 'password'}
                                                   name={'newPassword'}
                                                   label={'Crie uma senha'}
                                                   maxLength={'6'}
                                                   size={'xl'}
                                                   iconRight={<Button type={'button'}
                                                                      onClick={() => setShowPwd(!showPwd)}>
                                                       <Icon name={showPwd ? 'eye-off' : 'eye'}/>
                                                   </Button>}
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   value={values.newPassword}
                                                   isInvalid={errors.newPassword && touched.newPassword}
                                                   error={errors.newPassword}
                                    />
                                </div>
                            </>)
                        }
                        {tokenInput &&
                            <>
                                <div className={'form-group'}>
                                    <OutlinedInput
                                        name={'token'}
                                        label={'Informe o código de confirmação'}
                                        size={'xl'}
                                        maxLength={'6'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.token}
                                        isInvalid={errors.token && touched.token}
                                        error={errors.token}
                                    />
                                </div>
                            </>}
                        <div className="form-group">
                            <Button type="submit"
                                    size="lg"
                                    className="btn-block"
                                    color="primary"
                                    disabled={!isValid || isLoading}
                            >
                                {isLoading && (<Spinner size="sm" color="light"/>)}
                                {tokenInput ? 'CONFIRMAR' : 'CRIAR SENHA'}
                            </Button>
                        </div>
                    </Form>
                    <div className="form-note-s2 text-center pt-4">
                        <Link to={`${process.env.PUBLIC_URL}/login`}>Voltar para Login</Link>
                    </div>
                </PreviewCard>
            </Block>
            <AuthFooter/>
        </React.Fragment>;
    }
;
export default Register;
