import {useState} from "react";
import {
    Col,
    Row,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Card,
    Spinner
} from "reactstrap";
import {
    Button,
    Block,
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon
} from "../../../components/Component";
import {useCompanyContext} from "../../../context/CompanyContext";
import {useNavigate} from "react-router-dom";
import {companyType} from "../../../utils/Types"
import {parseToDateBR} from "../../../utils/Commons";
import CompaniesForm from "./Form";
import classnames from "classnames";

const PersonDetails = () => {
    const {companyData, isLoadingCompany} = useCompanyContext();
    const {ContractStatus} = {...companyData}
    const [activeTab, setActivetab] = useState("1");
    const navigate = useNavigate();

    if (ContractStatus !== 1 && ContractStatus !== 3) {
        return (
            <>
                <div>
                    {isLoadingCompany ? <Spinner color="primary"/> : <CompaniesForm/>}
                </div>
            </>
        )
    }

    return (
        <div>
            <BlockHead size="sm">
                <BlockBetween>
                    <BlockHeadContent>
                        <BlockTitle tag="h3" page>
                            Empresa / <strong className="text-primary small">{companyData.Name}</strong>
                        </BlockTitle>
                    </BlockHeadContent>
                    <BlockHeadContent>
                        <Button
                            color="light"
                            outline
                            className="bg-white d-none d-sm-inline-flex"
                            onClick={() => navigate(-1)}
                        >
                            <Icon name="arrow-left"></Icon>
                            <span>Voltar</span>
                        </Button>
                    </BlockHeadContent>
                </BlockBetween>
            </BlockHead>
            <Block>
                <Card className="card-bordered">
                    <div className="card-aside-wrap" id="user-detail-block">
                        <div className="card-content">
                            <Nav tabs className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                                <NavItem className="nav-item">
                                    <NavLink
                                        tag='a'
                                        href="#tab"
                                        className={classnames({active: activeTab === '1'})}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setActivetab('1');
                                        }}
                                    >
                                        <Icon name="user-circle"></Icon>
                                        <span>Dados Cadastrais</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="nav-item">
                                    <NavLink
                                        tag='a'
                                        href="#tab"
                                        className={classnames({active: activeTab === '2'})}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setActivetab('2');
                                        }}
                                    >
                                        <Icon name="location"/>
                                        <span>Endereço</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="nav-item">
                                    <NavLink
                                        tag='a'
                                        href="#tab"
                                        className={classnames({active: activeTab === '3'})}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setActivetab('3');
                                        }}
                                    >
                                        <Icon name="clip"/>
                                        <span>Documentos</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="nav-item">
                                    <NavLink
                                        tag='a'
                                        href="#tab"
                                        className={classnames({active: activeTab === '4'})}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setActivetab('4');
                                        }}
                                    >
                                        <Icon name="users"/>
                                        <span>Representante</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>
                    <div className="card-inner">
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId='1'>
                                <Block>
                                    <BlockHead className="nk-block-head-line">
                                        <BlockTitle tag="h6" className="overline-title text-base">
                                            Dados Cadastrais de empresa
                                        </BlockTitle>
                                    </BlockHead>
                                    <div className="profile-ud-list">
                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">CNPJ</span>
                                                <span className="profile-ud-value">{companyData.TaxNumber}</span>
                                            </div>
                                        </div>

                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">Natureza Jurídica</span>
                                                <span className="profile-ud-value">{companyType[companyData.Type]}</span>
                                            </div>
                                        </div>
                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">Razão Social</span>
                                                <span className="profile-ud-value">{companyData.Name}</span>
                                            </div>
                                        </div>
                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">Nome Fantasia</span>
                                                <span className="profile-ud-value">{companyData.BusinessName}</span>
                                            </div>
                                        </div>
                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">Entidade Associada</span>
                                                <span className="profile-ud-value">{companyData.Entity}</span>
                                            </div>
                                        </div>
                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">Data de fundação</span>
                                                <span className="profile-ud-value">{parseToDateBR(companyData.FoundationDate)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Block>
                                <div className="nk-divider divider md"></div>
                                <Block>
                                    <BlockHead className="nk-block-head-line">
                                        <BlockTitle tag="h6" className="overline-title text-base">
                                            Informações sobre o produto contratado, vencimento e vigência.
                                        </BlockTitle>
                                    </BlockHead>
                                    <div className="profile-ud-list">
                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">Produto Contratado</span>
                                                <span className="profile-ud-value">{companyData.Project}</span>
                                            </div>
                                        </div>
                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">Data de assinatura</span>
                                                <span className="profile-ud-value">{parseToDateBR(companyData.DateContract)}</span>
                                            </div>
                                        </div>
                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">Data de vigência</span>
                                                <span className="profile-ud-value">{parseToDateBR(companyData.EffectiveDate)}</span>
                                            </div>
                                        </div>
                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">Dia do vencimento</span>
                                                <span className="profile-ud-value">{companyData.DueDate}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Block>
                            </TabPane>
                            <TabPane tabId='2'>
                                <Block>
                                    <BlockHead className="nk-block-head-line">
                                        <BlockTitle tag="h6" className="overline-title text-base">
                                            Informações de endereço e contatos da empresa
                                        </BlockTitle>
                                    </BlockHead>
                                    <div className="profile-ud-list">
                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">CEP</span>
                                                <span className="profile-ud-value">{companyData.Address.Zipcode}</span>
                                            </div>
                                        </div>
                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">Logradouro</span>
                                                <span className="profile-ud-value">{companyData.Address.Street}</span>
                                            </div>
                                        </div>
                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">Número</span>
                                                <span className="profile-ud-value">{companyData.Address.Number}</span>
                                            </div>
                                        </div>

                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">Complemento</span>
                                                <span
                                                    className="profile-ud-value">{companyData.Address.Complement}</span>
                                            </div>
                                        </div>
                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">Bairro</span>
                                                <span
                                                    className="profile-ud-value">{companyData.Address.Region}</span>
                                            </div>
                                        </div>
                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">Cidade</span>
                                                <span className="profile-ud-value">{companyData.Address.City}</span>
                                            </div>
                                        </div>
                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">UF</span>
                                                <span className="profile-ud-value">{companyData.Address.State}</span>
                                            </div>
                                        </div>
                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">Telefone</span>
                                                <span className="profile-ud-value">{companyData.Address.Phone}</span>
                                            </div>
                                        </div>
                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">Telefone Alternativo</span>
                                                <span className="profile-ud-value">{companyData.Address.PhoneAlt}</span>
                                            </div>
                                        </div>
                                        <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                                <span className="profile-ud-label">E-mail</span>
                                                <span className="profile-ud-value">{companyData.Address.Email}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Block>
                            </TabPane>
                            <TabPane tabId='3'>
                                <Block>
                                    <BlockHead className="nk-block-head-line">
                                        <BlockTitle tag="h6" className="overline-title text-base">
                                            Arquivos anexados das documentações exigidas para contratação.
                                        </BlockTitle>
                                    </BlockHead>
                                    <Block>
                                        <Row spacing={2}>
                                            {companyData.Documents && companyData.Documents.map((file, index) => (
                                                <Col key={index}>
                                                    <Card className="card-bordered gallery ">
                                                        <div className='d-flex flex-column justify-content-center align-items-center'>
                                                            <img src={file.Src} alt="preview" width='120px' height='120px' />
                                                            <span>{file.TypeDescription}</span>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Block>
                                </Block>
                            </TabPane>
                            <TabPane tabId='4'>
                                <Block>
                                    <BlockHead className="nk-block-head-line">
                                        <BlockTitle tag="h6" className="overline-title text-base">
                                            Informações do representante legal da empresa
                                        </BlockTitle>
                                    </BlockHead>
                                    {companyData.Holders && companyData.Holders.length > 0 &&
                                        <div className="profile-ud-list">
                                            <div className="profile-ud-item">
                                                <div className="profile-ud wider">
                                                    <span className="profile-ud-label">Nome Completo</span>
                                                    <span className="profile-ud-value">{companyData.Holders[0].FullName}</span>
                                                </div>
                                            </div>
                                            <div className="profile-ud-item">
                                                <div className="profile-ud wider">
                                                    <span className="profile-ud-label">CPF</span>
                                                    <span className="profile-ud-value">{companyData.Holders[0].TaxNumber}</span>
                                                </div>
                                            </div>
                                            <div className="profile-ud-item">
                                                <div className="profile-ud wider">
                                                    <span className="profile-ud-label">RG</span>
                                                    <span className="profile-ud-value">{companyData.Holders[0].Identity}</span>
                                                </div>
                                            </div>
                                            <div className="profile-ud-item">
                                                <div className="profile-ud wider">
                                                    <span className="profile-ud-label">Data de Nascimento</span>
                                                    <span className="profile-ud-value">{parseToDateBR(companyData.Holders[0].BirthDate)}</span>
                                                </div>
                                            </div>
                                            <div className="profile-ud-item">
                                                <div className="profile-ud wider">
                                                    <span className="profile-ud-label">Nacionalidade</span>
                                                    <span className="profile-ud-value">{companyData.Holders[0].Nationality}</span>
                                                </div>
                                            </div>
                                            <div className="profile-ud-item">
                                                <div className="profile-ud wider">
                                                    <span className="profile-ud-label">Cidade natal</span>
                                                    <span className="profile-ud-value">{companyData.Holders[0].BirthCity}</span>
                                                </div>
                                            </div>
                                            <div className="profile-ud-item">
                                                <div className="profile-ud wider">
                                                    <span className="profile-ud-label">UF natal</span>
                                                    <span className="profile-ud-value">{companyData.Holders[0].BirthState}</span>
                                                </div>
                                            </div>
                                            <div className="profile-ud-item">
                                                <div className="profile-ud wider">
                                                    <span className="profile-ud-label">Gênero</span>
                                                    <span className="profile-ud-value">{companyData.Holders[0].Gender}</span>
                                                </div>
                                            </div>
                                            <div className="profile-ud-item">
                                                <div className="profile-ud wider">
                                                    <span className="profile-ud-label">Nome da mãe</span>
                                                    <span className="profile-ud-value">{companyData.Holders[0].MotherFullName}</span>
                                                </div>
                                            </div>
                                            <div className="profile-ud-item">
                                                <div className="profile-ud wider">
                                                    <span className="profile-ud-label">Nome do pai</span>
                                                    <span className="profile-ud-value">{companyData.Holders[0].FatherFullName}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Block>
                            </TabPane>
                        </TabContent>
                    </div>
                </Card>
            </Block>
        </div>
    )
};
export default PersonDetails;
