import {
    createContext,
    useCallback,
    useContext,
    useEffect, useMemo,
    useState
} from 'react';
import {toast} from "react-toastify";
import {toastOptions} from "../utils/Presets";
import httpClient from "../utils/HttpClient";

const PersonContext = createContext({});

export const PersonProvider = ({children, personId}) => {
    const [isLoading, setLoading] = useState(false);
    const [dependents, setDependents] = useState([]);
    const [personData, setPersonData] = useState(null);

    const fetchPerson = useCallback(async () => {
        try {
            setLoading(true);
            if (!personId) return;
            const res = await httpClient.get(`/assinante/${personId}`);
            const {data} = res.data;
            const {
                Id,
                Uuid,
                FullName,
                TaxNumber,
                Identity,
                IdentityIssuer,
                IdentityState,
                IdentityDate,
                Genre,
                BirthDate,
                CompanyId,
                ContractDate,
                ContractStatus,
                ProjectId,
                Product,
                DueDate,
                EffectiveDate,
                BirthCity,
                BirthState,
                Nationality,
                MotherFullName,
                FatherFullName,
                MaritalStatus,
                SpouseFullName,
                Occupation,
                Profession,
                Education,
                EmployerCompany,
                Address,
                Contacts,
                SendCardId,
                Documents,
            } = {...data};
            const {
                ZipCode,
                Street,
                Number,
                Complement,
                Neighborhood,
                City,
                State,
            } = {...Address};
            const {
                Phone,
                PhoneAlt,
                Email
            } = {...Contacts};
            const FileCPF = [];
            const FileProofOfResidence = [];
            const FileDriveLicenseFront = [];
            const FileDriveLicenseBack = [];
            const FileIdentityFront = [];
            const FileIdentityBack = [];
            for (const index in Documents) {
                const {Src, Type, FileTypeDescription} = {...Documents[index]};
                switch (Type) {
                    case 1:
                        FileCPF.push({preview: Src, name: FileTypeDescription});
                        break;
                    case 2:
                        FileProofOfResidence.push({preview: Src, name: FileTypeDescription});
                        break;
                    case 3:
                        FileDriveLicenseFront.push({preview: Src, name: FileTypeDescription});
                        break;
                    case 16:
                        FileDriveLicenseBack.push({preview: Src, name: FileTypeDescription});
                        break;
                    case 0:
                        FileIdentityFront.push({preview: Src, name: FileTypeDescription});
                        break;
                    case 7:
                        FileIdentityBack.push({preview: Src, name: FileTypeDescription});
                        break;
                    default:
                }
            }
            setPersonData({
                id: Id,
                uuid: Uuid,
                taxNumber: TaxNumber,
                fullName: FullName,
                identity: Identity,
                identityIssuer: IdentityIssuer,
                identityState: IdentityState,
                identityDate: IdentityDate,
                birthDate: BirthDate,
                nationality: Nationality,
                birthCity: BirthCity,
                birthState: BirthState,
                genre: Genre,
                motherFullName: MotherFullName,
                fatherFullName: FatherFullName,
                maritalStatus: MaritalStatus?.toString(),
                spouseFullName: SpouseFullName,
                occupation: Occupation,
                employerCompany: EmployerCompany,
                addressZipcode: ZipCode,
                addressStreet: Street,
                addressNumber: Number,
                addressComplement: Complement,
                addressNeighborhood: Neighborhood,
                addressCity: City,
                addressState: State,
                contactPhone: Phone,
                contactPhoneAlt: PhoneAlt,
                contactEmail: Email,
                companyId: CompanyId,
                projectId: ProjectId,
                product: Product,
                profession: Profession,
                education: Education,
                contractDate: ContractDate,
                contractStatus: ContractStatus,
                effectiveDate: EffectiveDate,
                dueDate: DueDate,
                fileProofOfResidence: FileProofOfResidence,
                fileCPF: FileCPF,
                fileDriveLicenseFront: FileDriveLicenseFront,
                fileDriveLicenseBack: FileDriveLicenseBack,
                fileIdentityFront: FileIdentityFront,
                fileIdentityBack: FileIdentityBack,
                sendCardId: SendCardId || false,
                documents: Documents
            });
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
        } finally {
            setLoading(false);
        }
    }, [personId]);

    const fetchDependents = useCallback(async () => {
        try {
            if (!personId) return;
            const res = await httpClient.get(`/titular/${personId}/dependentes`);
            const {data} = res.data;
            const deps = [];
            for (const k in data) {
                const {
                    Id,
                    DegreeOfRelatedness,
                    FullName,
                    TaxNumber,
                    Nationality,
                    BirthDate,
                    BirthCity,
                    BirthState,
                    Genre,
                    MotherFullName,
                    FatherFullName,
                    Email,
                    Phone,
                } = {...data[k]};
                deps.push({
                    depHolderId: personId,
                    depId: Id,
                    depDegreeOfRelatedness: DegreeOfRelatedness,
                    depFullName: FullName,
                    depTaxNumber: TaxNumber,
                    depNationality: Nationality,
                    depBirthDate: BirthDate,
                    depBirthCity: BirthCity,
                    depBirthState: BirthState,
                    depGenre: Genre,
                    depMotherFullName: MotherFullName,
                    depFatherFullName: FatherFullName,
                    depEmail: Email,
                    depPhone: Phone
                });
            }
            setDependents(deps);
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
        }
    }, [personId]);

    useEffect(() => {
        fetchPerson().then()
    }, [fetchPerson]);

    useEffect(() => {
        fetchDependents().then()
    }, [fetchDependents]);

    const values = useMemo(
        () => ({
            isLoading,
            personData,
            dependents,
            fetchDependents,
        }),
        [isLoading,
            personData,
            dependents,
            fetchDependents],
    );

    return (
        <PersonContext.Provider value={values}>
            {children}
        </PersonContext.Provider>
    );
};
export const usePersonContext = () => {
    return useContext(PersonContext);
};