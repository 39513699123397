import React, {useState} from "react";
import {
    Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, BlockDes, Button, Icon, OutlinedInput
} from "../../components/Component";
import {Alert, Badge, Card, Form, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import {useAuth} from "../../hooks/useAuth";
import {weekdaysName} from "../../utils/Commons";
import {useFormik} from "formik";
import httpClient from "../../utils/HttpClient";
import {toastOptions} from "../../utils/Presets";
import {toast} from "react-toastify";
import Head from "../../layout/head/Head";
import * as Yup from 'yup';

const Security = () => {
    const [isLoading, setLoading] = useState(false);
    const [modalForm, setModalForm] = useState(false);
    const [showPwd, setShowPwd] = useState(false);
    const [errSecurity, setErrSecurity] = useState(null);
    const toggleForm = () => setModalForm(!modalForm);
    const {user, logout} = useAuth();

    const {
        handleSubmit,
        handleBlur,
        handleChange,
        touched,
        errors,
        values,
        resetForm,
        isValid
    } = useFormik({
        initialValues: {
            password: '',
            newPassword: ''
        },
        validationSchema: Yup.object({
            password: Yup.string().required('Campo obrigatório'),
            newPassword: Yup.string()
                .required('Campo obrigatório')
                .test('passwords-match', 'As senhas não podem ser iguais', function (value) {
                    return value !== this.parent.password;
                })
        }),
        onSubmit: async v => {
            try {
                setLoading(true);
                await httpClient.put('vendas/usuario/senha/trocar', {
                    login: user.Login,
                    password: v.password,
                    newPassword: v.newPassword
                })
                toast.success('Senha alterada com sucesso', {
                    ...toastOptions,
                });
                await resetForm();
                setModalForm(false);
            } catch (err) {
                if (err.response) {
                    const {errors} = {...err.response.data};
                    Object.entries(errors).forEach(([, value]) => {
                        setErrSecurity(value);
                    })
                }
            } finally {
                setLoading(false);
            }
        }
    })

    return (<React.Fragment>
        <Head title="Credenciais de acesso"></Head>
        <Block>
            <BlockHead size="md">
                <BlockBetween>
                    <BlockHeadContent>
                        <BlockTitle tag="h4">Credenciais de acesso</BlockTitle>
                        <BlockDes>
                            <p>Informações da sua credencial de acesso ao Portal de Vendas.</p>
                        </BlockDes>
                    </BlockHeadContent>
                </BlockBetween>
            </BlockHead>
            <Card className="card-bordered">
                <div className="card-inner-group">
                    <div className="card-inner">
                        <div className="between-center flex-wrap flex-md-nowrap g-3">
                            <div className="nk-block-text">
                                <h6>Login de usuário</h6>
                                <p>{user.Email}</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-inner">
                        <div className="between-center flex-wrap g-3">
                            <div className="nk-block-text">
                                <h6>Trocar senha</h6>
                                <p>Defina uma senha segura para proteger o acesso à sua conta.</p>
                            </div>
                            <div className="nk-block-actions flex-shrink-sm-0">
                                <Button color="primary" onClick={toggleForm}>Trocar senha</Button>
                            </div>

                        </div>
                    </div>
                    <div className="card-body">
                        <div className="between-center flex-wrap flex-md-nowrap g-3">
                            <div className="nk-block-text">
                                <h6>
                                    Expediente de acesso
                                </h6>
                                <p>
                                    Seu acesso à Plataforma de Vendas está autorizado entre os horários de <span
                                    className={'fw-bold'}>{user.Expedient[0]}</span> a <span
                                    className={'fw-bold'}>{user.Expedient[1]}</span>
                                </p>
                                <p className={'mb-1'}>Nos seguintes dias da semana:</p>
                                <ul className={'d-flex flex-shrink-1 g-1 flex-row'}>
                                    {user.AccessDaysAllow.map(([key]) => (<li key={key.toString()}>
                                        <Badge pill
                                               color="primary">{weekdaysName(key, false)}
                                        </Badge>
                                    </li>))}
                                </ul>
                            </div>
                            <div className="nk-block-actions">
                                <Button type={'button'} color="danger" onClick={logout}>Desconectar</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
            <Modal isOpen={modalForm} toggle={toggleForm}>
                <ModalHeader
                    toggle={toggleForm}
                    close={<button className="close" onClick={toggleForm}>
                        <Icon name="cross"/>
                    </button>}
                >
                    Troca de Senha
                </ModalHeader>
                <ModalBody>
                    {errSecurity && (
                        <div className="mb-3">
                            <Alert color="danger" className="alert-icon">
                                <Icon name="alert-circle"/> {errSecurity}
                            </Alert>
                        </div>)
                    }
                    <p>Informe a senha atual, e uma nova senha para confirmar.</p>
                    <Form onSubmit={handleSubmit}>
                        <div className={'form-group'}>
                            <OutlinedInput type={showPwd ? 'text' : 'password'}
                                           name={'password'}
                                           label={'Senha atual'}
                                           maxLength={'6'}
                                           size={'xl'}
                                           iconRight={<Button type={'button'}
                                                              onClick={() => setShowPwd(!showPwd)}>
                                               <Icon name={showPwd ? 'eye-off' : 'eye'}/>
                                           </Button>}
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.password}
                                           isInvalid={errors.password && touched.password}
                                           error={errors.password}
                            />
                        </div>
                        <div className={'form-group'}>
                            <OutlinedInput type={showPwd ? 'text' : 'password'}
                                           name={'newPassword'}
                                           label={'Nova Senha'}
                                           maxLength={'6'}
                                           size={'xl'}
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.newPassword}
                                           isInvalid={errors.newPassword && touched.newPassword}
                                           error={errors.newPassword}
                            />
                        </div>
                        <div className="form-group">
                            <Button
                                color="primary"
                                size="lg"
                                className="btn-block"
                                type='submit'
                                disabled={!isValid || isLoading}
                            >
                                {
                                    isLoading ? <Spinner size={'sm'}/> : 'MUDAR SENHA'
                                }
                            </Button>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter className="bg-light">
                    <p className="text-soft">&copy; TokSaúde.</p>
                </ModalFooter>
            </Modal>
        </Block>
    </React.Fragment>);
};

export default Security;
