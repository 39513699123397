import {
    createContext,
    useCallback,
    useContext,
    useMemo
} from 'react';
import {useNavigate} from 'react-router-dom';
import useLocalStorage from './useLocalStorage';
import PropTypes from "prop-types";
import httpClient from "../utils/HttpClient";

const {REACT_APP_INSTANCE} = process.env.REACT_APP_INSTANCE;
const AuthContext = createContext(undefined);

const AuthProvider = (props) => {
    const {
        initialPath,
        loginPath,
        children
    } = props;
    const [user, setUser] = useLocalStorage(`${REACT_APP_INSTANCE}:user`, null);
    const navigate = useNavigate();
    const login = useCallback(async (data) => {
        try {
            const {GroupName, OwnerId} = {...data};
            if (GroupName?.toLowerCase() === 'corretor') {
                const resp = await httpClient.get(`credenciamento/corretor/${OwnerId}`);
                const Profile = {...resp.data.data};
                data.Profile = {...Profile}; 
            }
            setUser(data);
            navigate(initialPath);
        } catch (e) {
        }
    }, [initialPath, navigate, setUser]);
    const logout = useCallback(() => {
        setUser(null);
        navigate(loginPath, {replace: true});
    }, [loginPath, navigate, setUser]);
    const value = useMemo(
        () => ({
            user,
            login,
            logout,
        }),
        [login, logout, user],
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.any,
    loginPath: PropTypes.string,
    initialPath: PropTypes.string
}

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;

