import {useEffect, useState} from "react";
import {Icon, UserAvatar} from "../../../components/Component";
import {findUpper} from "../../../utils/Utils";
import {Button} from "reactstrap";
import * as React from "react";
import Swal from "sweetalert2";

const DependentsList = ({onDelete, data, isLoading, onRowClick}) => {
    const [dependentData, setDependentData] = useState([]);

    useEffect(() => {
        setDependentData(data)
    }, [data]);

    const handleDelete = (rowDelete) => async () => {
        const confirm = await Swal.fire({
            title: 'Excluir dependente',
            text: `Esta ação irá remover ${rowDelete.depFullName} da lista de dependentes. Deseja continuar?`,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            showCancelButton: true
        });
        if (confirm.isConfirmed) {
            const newData = dependentData.filter((row) => (row.depTaxNumber !== rowDelete.depTaxNumber));
            if (onDelete) onDelete(rowDelete, newData);
            setDependentData(newData);
        }
    };

    const handleEdit = (rowEdit) => () => {
        if (onRowClick) {
            onRowClick(rowEdit);
        }
    }

    return <React.Fragment>
        <h6>Dependentes:</h6><br/>
        <ul className="nk-support">
            {
                dependentData.map((item, key) => (
                    <li className="nk-support-item p-1"
                        key={key}>
                        <UserAvatar image={item.image} text={findUpper(item.depFullName)}/>
                        <div className="nk-support-content">
                            <div className="title">
                                <span>{item.depFullName}</span>
                                <div className={'d-flex column-gap-1'}>
                                    {onRowClick && (
                                        <Button className="btn-round btn-icon"
                                                color="primary"
                                                size="sm"
                                                disabled={isLoading}
                                                onClick={handleEdit(item)}>
                                            <Icon name="edit"/>
                                        </Button>
                                    )}
                                    <Button className="btn-round btn-icon"
                                            color="danger"
                                            size="sm"
                                            disabled={isLoading}
                                            onClick={handleDelete(item)}>
                                        <Icon name="trash"/>
                                    </Button>
                                </div>
                            </div>
                            <p>{item.depDegreeOfRelatedness}</p>
                            <span className="time">CPF: {item.depTaxNumber}</span>
                        </div>
                    </li>
                ))
            }
        </ul>
    </React.Fragment>
}
export default DependentsList;
