import {useFormik} from "formik";
import React, {useCallback, useEffect, useState} from "react";
import {
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import {CPF, PHONE} from "../../../utils/Masks";
import {degreeOfRelatedness, genres, states} from "../../../utils/Types";
import {onlyNumber, parseToISODate} from "../../../utils/Commons";
import {toast} from "react-toastify";
import {toastOptions} from "../../../utils/Presets";
import * as Yup from "yup";
import OutlinedSelect from "../../../components/select/OutlinedSelect";
import OutlinedInput from "../../../components/input/outlined-input/OutlinedInput";
import httpClient from "../../../utils/HttpClient";
import Button from "../../../components/button/Button";
import Icon from "../../../components/icon/Icon";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const DependentsModal = ({isOpen, toggle, onSave, holderId, dependentId}) => {
    const [isLoading, setLoading] = useState(false);
    const [isFormLoading, setFormLoading] = useState(false);
    const [degreeOfRelatednessList, setDegreeOfRelatednessList] = useState([]);
    const [genresList, setGenresList] = useState([]);
    const [statesList, setStatesList] = useState([]);

    const {
        values,
        errors,
        touched,
        isValid,
        resetForm,
        setFieldValue,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit
    } = useFormik({
        initialValues: {
            depId: '',
            depTaxNumber: '',
            depFullName: '',
            depDegreeOfRelatedness: '',
            depBirthDate: '',
            depNationality: '',
            depBirthCity: '',
            depBirthState: '',
            depGender: '',
            depMotherFullName: '',
            depFatherFullName: '',
            depPhone: '',
            depEmail: '',
        },
        validationSchema: Yup.object({
            depTaxNumber: Yup.string().required('Preencha este campo'),
            depFullName: Yup.string().required('Preencha este campo'),
            depDegreeOfRelatedness: Yup.string().required('Preencha este campo'),
            depBirthDate: Yup.string().required('Preencha este campo'),
            depNationality: Yup.string().required('Preencha este campo'),
            depBirthCity: Yup.string().required('Preencha este campo'),
            depBirthState: Yup.string().required('Preencha este campo'),
            depGender: Yup.string().required('Preencha este campo'),
            depMotherFullName: Yup.string().required('Preencha este campo'),
            depEmail: Yup.string().required('Preencha este campo').email('Formato inválido para e-mail'),
            depPhone: Yup.string().required('Preencha este campo'),
        }),
        onSubmit: async (v) => {
            try {
                setLoading(true);
                let url = `titular/${holderId}/dependente`;
                let method = 'POST';
                if (v.depId) {
                    url += `/${v.depId}`;
                    method = 'PUT';
                }
                const resp = await httpClient.request({
                    method,
                    url,
                    data: {
                        degreeOfRelatedness: v.depDegreeOfRelatedness,
                        fullName: v.depFullName,
                        taxNumber: v.depTaxNumber,
                        nationality: v.depNationality,
                        birthDate: v.depBirthDate,
                        birthCity: v.depBirthCity,
                        birthState: v.depBirthState,
                        gender: v.depGender,
                        motherFullName: v.depMotherFullName,
                        fatherFullName: v.depFatherFullName,
                        email: v.depEmail,
                        phone: v.depPhone,
                        contractStatus: 3,
                    }
                });
                const {data} = {...resp.data};
                resetForm();
                if (onSave) await onSave(data);
                toast.success('Registro salvo com sucesso', {
                    ...toastOptions
                });
            } catch (err) {
                const {errors} = {...err.response.data};
                if (typeof errors === 'object') {
                    for (const key in errors) {
                        toast.error(errors[key], {
                            ...toastOptions
                        });
                    }
                } else {
                    toast.error(err.message(), {
                        ...toastOptions
                    });
                }
            } finally {
                setLoading(false);
            }
        }
    });

    const fetchDegreeOfRelatedness = () => {
        const options = [];
        for (const key in degreeOfRelatedness) {
            options.push({label: degreeOfRelatedness[key], value: degreeOfRelatedness[key]});
        }
        setDegreeOfRelatednessList(options);
    }

    const fetchGender = () => {
        const options = [];
        for (const key in genres) {
            options.push({label: genres[key], value: genres[key][0]});
        }
        setGenresList(options);
    }

    const fetchStates = () => {
        const options = [];
        for (const key in states) {
            options.push({label: states[key], value: states[key]});
        }
        setStatesList(options);
    }

    const _selectedValue = (list, value) => {
        return list.filter((opt) => {
            return opt?.value.toString() === value?.toString()
        })
    };

    const _queryCPF = (taxNumber, fieldName, fieldBirthDate) => async () => {
        try {
            if (!taxNumber) return;
            setLoading(true)
            const res = await httpClient.get(`extra/consultar/cpf/${onlyNumber(taxNumber)}?data=01/01/2001`);
            const {
                nome_da_pf,
                data_nascimento,
            } = {...res.data.data};
            await setFieldValue(fieldName, nome_da_pf, false);
            await setFieldValue(fieldBirthDate, parseToISODate(data_nascimento), false);
        } catch (e) {
            const {errors} = {...e.response.data};
            if (typeof errors === 'object') {
                for (const key in errors) {
                    toast.error(errors[key], {
                        ...toastOptions
                    });
                }
                if (e.response.status.toString().includes("40")) {
                    toast.error("Confira se a Data de Nascimento e o número de CPF estão corretos", {...toastOptions});
                }
            }
        } finally {
            setLoading(false);
        }
    }

    const _fetchData = useCallback(async () => {
        try {
            await resetForm();
            if (!dependentId) return;

            setFormLoading(true);
            setTimeout(() => {
                setFormLoading(false);
            }, 1000);

            const res = await httpClient.get(`titular/${holderId}/dependente/${dependentId}`);
            const {
                Id,
                TaxNumber,
                FullName,
                DegreeOfRelatedness,
                Nationality,
                BirthDate,
                BirthCity,
                BirthState,
                Gender,
                MotherFullName,
                FatherFullName,
                Phone,
                Email,
            } = {...res.data.data};
            await setValues({
                depId: Id || '',
                depTaxNumber: TaxNumber || '',
                depFullName: FullName || '',
                depDegreeOfRelatedness: DegreeOfRelatedness || '',
                depNationality: Nationality || '',
                depBirthDate: BirthDate || '',
                depBirthCity: BirthCity || '',
                depBirthState: BirthState || '',
                depGender: Gender || '',
                depMotherFullName: MotherFullName || '',
                depFatherFullName: FatherFullName || '',
                depPhone: Phone || '',
                depEmail: Email || '',
            }, true);
        } catch (e) {
        } finally {
            setLoading(false);
        }
    }, [dependentId, holderId, resetForm, setValues]);

    useEffect(() => {
        fetchDegreeOfRelatedness();
        fetchGender();
        fetchStates();
    }, []);

    useEffect(() => {
        _fetchData().then();
    }, [_fetchData]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} size={'xl'}>
            <ModalHeader
                toggle={toggle}
                close={
                    <button
                        type={"button"}
                        disabled={isLoading}
                        className="close"
                        onClick={toggle}>
                        <Icon name="cross"/>
                    </button>
                }>
                Dependente
            </ModalHeader>
            <ModalBody>
                {isFormLoading ? (
                    <div>
                        <Row>
                            <Col xl={4}>
                                <Skeleton height="50px"/>
                            </Col>
                            <Col xl={8}>
                                <Skeleton height="50px"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={4}>
                                <Skeleton height="50px"/>
                            </Col>
                            <Col xl={4}>
                                <Skeleton height="50px"/>
                            </Col>
                            <Col xl={4}>
                                <Skeleton height="50px"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <Skeleton height="50px"/>
                            </Col>
                            <Col xl={2}>
                                <Skeleton height="50px"/>
                            </Col>
                            <Col xl={4}>
                                <Skeleton height="50px"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <Skeleton height="50px"/>
                            </Col>
                            <Col xl={6}>
                                <Skeleton height="50px"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={4}>
                                <Skeleton height="50px"/>
                            </Col>
                            <Col xl={8}>
                                <Skeleton height="50px"/>
                            </Col>
                        </Row>

                    </div>
                ) : (
                    <div>
                        <Row className={'g-3'}>
                            <Col lg={4} md={12}>
                                <OutlinedInput
                                    id={'depTaxNumber'}
                                    mask={CPF}
                                    label={'CPF'}
                                    size={'xl'}
                                    value={values.depTaxNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    isInvalid={errors.depTaxNumber && touched.depTaxNumber}
                                    error={errors.depTaxNumber}
                                    iconRight={
                                        <Button
                                            type={'button'}
                                            className={'btn-icon me-2'}
                                            onClick={_queryCPF(values.depTaxNumber, 'depFullName', 'depBirthDate')}>
                                            {isLoading ? <Spinner size={'sm'}/> : <Icon name={'search'}/>}
                                        </Button>
                                    }
                                />
                            </Col>
                            <Col lg={8} md={8}>
                                <OutlinedInput
                                    id={'depFullName'}
                                    name={'depFullName'}
                                    label="Nome completo"
                                    size={'xl'}
                                    value={values.depFullName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    isInvalid={errors.depFullName && touched.depFullName}
                                    error={errors.depFullName}/>
                            </Col>
                            <Col lg={4} md={4}>
                                <OutlinedSelect
                                    id={'depDegreeOfRelatedness'}
                                    name={'depDegreeOfRelatedness'}
                                    label={'Grau parentesco'}
                                    size={'xl'}
                                    placeholder={'Selecione'}
                                    options={degreeOfRelatednessList}
                                    value={_selectedValue(degreeOfRelatednessList, values.depDegreeOfRelatedness)}
                                    onChange={(opt) => setFieldValue('depDegreeOfRelatedness', opt?.value)}
                                    onBlur={handleBlur}
                                    required
                                    isInvalid={errors.depDegreeOfRelatedness && touched.depDegreeOfRelatedness}
                                    error={errors.depDegreeOfRelatedness}/>
                            </Col>
                            <Col lg={4} md={6}>
                                <OutlinedInput
                                    id={'depBirthDate'}
                                    name={'depBirthDate'}
                                    type={'date'}
                                    label={'Data de nascimento'}
                                    size={'xl'}
                                    value={values.depBirthDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    isInvalid={errors.depBirthDate && touched.depBirthDate}
                                    error={errors.depBirthDate}/>
                            </Col>
                            <Col lg={4} md={6}>
                                <OutlinedInput
                                    id={'depNationality'}
                                    name={'depNationality'}
                                    label={'Nacionalidade'}
                                    size={'xl'}
                                    value={values.depNationality}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    isInvalid={errors.depNationality && touched.depNationality}
                                    error={errors.depNationality}/>
                            </Col>
                            <Col lg={6} md={5}>
                                <OutlinedInput
                                    id={'depBirthCity'}
                                    name={'depBirthCity'}
                                    label={'Cidade natal'}
                                    size={'xl'}
                                    value={values.depBirthCity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    isInvalid={errors.depBirthCity && touched.depBirthCity}
                                    error={errors.depBirthCity}/>
                            </Col>
                            <Col lg={2} md={3}>
                                <OutlinedSelect
                                    id={'depBirthState'}
                                    name={'depBirthState'}
                                    label={'UF natal'}
                                    size={'xl'}
                                    placeholder={'Selecione'}
                                    options={statesList}
                                    value={_selectedValue(statesList, values.depBirthState)}
                                    onChange={(opt) => setFieldValue('depBirthState', opt?.value)}
                                    onBlur={handleBlur}
                                    required
                                    isInvalid={errors.depBirthState && touched.depBirthState}
                                    error={errors.depBirthState}/>
                            </Col>
                            <Col lg={4} md={4}>
                                <OutlinedSelect
                                    id={'depGender'}
                                    name={'depGender'}
                                    options={genresList}
                                    label={'Gênero'}
                                    size={'xl'}
                                    placeholder={'Selecione'}
                                    value={_selectedValue(genresList, values.depGender)}
                                    onChange={(opt) => setFieldValue('depGender', opt?.value)}
                                    onBlur={handleBlur}
                                    required
                                    isInvalid={errors.depGender && touched.depGender}
                                    error={errors.depGender}/>
                            </Col>
                            <Col lg={6} md={6}>
                                <OutlinedInput
                                    id={'depMotherFullName'}
                                    name={'depMotherFullName'}
                                    label={'Nome da mãe'}
                                    size={'xl'}
                                    value={values.depMotherFullName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    isInvalid={errors.depMotherFullName && touched.depMotherFullName}
                                    error={errors.depMotherFullName}/>
                            </Col>
                            <Col lg={6} md={6}>
                                <OutlinedInput
                                    id={'depFatherFullName'}
                                    name={'depFatherFullName'}
                                    label={'Nome da pai'}
                                    size={'xl'}
                                    value={values.depFatherFullName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}/>
                            </Col>
                            <Col lg={4} md={4}>
                                <OutlinedInput
                                    mask={PHONE}
                                    id={'depPhone'}
                                    name={'depPhone'}
                                    label={'Telefone'}
                                    size={'xl'}
                                    required
                                    value={values.depPhone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.depPhone && touched.depPhone}
                                    error={errors.depPhone}/>
                            </Col>
                            <Col lg={8} md={8}>
                                <OutlinedInput
                                    id={'depEmail'}
                                    name={'depEmail'}
                                    type={'email'}
                                    label={'E-mail'}
                                    size={'xl'}
                                    required
                                    value={values.depEmail}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.depEmail && touched.depEmail}
                                    error={errors.depEmail}/>
                            </Col>
                        </Row>
                    </div>
                )}
            </ModalBody>
            <ModalFooter className="bg-light">
                <Button type={'button'}
                        color={'default'}
                        disabled={isLoading}
                        onClick={toggle}>
                    CANCELAR
                </Button>
                <Button type={'button'}
                        color={'primary'}
                        disabled={!isValid || isLoading}
                        onClick={handleSubmit}>
                    {isLoading ? <Spinner size="sm" color="light"/> : "SALVAR"}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default DependentsModal;
