import Router from "./route/Index";
import ThemeProvider from "./layout/provider/Theme";
import AuthProvider from "./hooks/useAuth";
import {ToastContainer} from "react-toastify";
import React from "react";

const App = () => {
    return (
        <ThemeProvider>
            <AuthProvider initialPath={'/'} loginPath={'/login'}>
                <Router/>
                <ToastContainer/>
            </AuthProvider>
        </ThemeProvider>
    );
};
export default App;
