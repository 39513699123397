import React, {useCallback, useEffect, useState} from "react";
import {
    Badge,
    Nav,
    NavItem,
    NavLink,
    Spinner,
    TabContent,
    TabPane
} from "reactstrap";
import {Col, Row} from "../../../components/grid/Grid";
import {CPF, ZIPCODE, PHONE} from "../../../utils/Masks";
import {useFormik} from "formik";
import {onlyNumber, parseToISODate, zipCode} from "../../../utils/Commons";
import {genres, states} from "../../../utils/Types";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {toastOptions} from "../../../utils/Presets";
import {dueDates} from "../../../utils/Types";
import {useAuth} from "../../../hooks/useAuth";
import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Button,
    Icon
} from "../../../components/Component";
import Dropzone from "react-dropzone";
import OutlinedInput from "../../../components/input/outlined-input/OutlinedInput";
import OutlinedSelect from "../../../components/select/OutlinedSelect";
import * as Yup from "yup";
import httpClient from "../../../utils/HttpClient";
import useMediaQuery from "../../../hooks/useMediaQuery";
import classnames from "classnames";
import DependentsList from "./DependentsList";
import DependentsModal from "./DependentsModal";
import Swal from "sweetalert2";
import moment from "moment";
import Head from "../../../layout/head/Head";
import OutlinedAsyncSelect from "../../../components/select/OutlinedAsyncSelect";

const dropzoneDefaults = {
    'multiple': false,
    'maxSize': 5242880,
    'accept': ["image/*", "application/pdf", "text/plain"],
}

const today = moment().format('YYYY-MM-DD');

const PersonsForm = () => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const [companiesList, setCompaniesList] = useState([]);
    const [projectsList, setProjectsList] = useState([]);
    const [statesList, setStatesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [genresList, setGenresList] = useState([]);
    const [identityIssList, setIdentityIssList] = useState([]);
    const [professionList, setProfessionList] = useState([]);
    const [occupationTypeList, setOccupationTypeList] = useState([]);
    const [occupationList, setOccupationList] = useState([]);
    const [maritalStatusList, setMaritalStatusList] = useState([]);
    const [educationList, setEducationList] = useState([]);
    const [companyInput, setCompanyInput] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isLoadingProjects, setLoadingProjects] = useState(false);
    const [isLoadingCompanies, setLoadingCompanies] = useState(false);
    const [isLoadingAddress, setLoadingAddress] = useState(false);
    const [isLoadingDeps] = useState(false);
    const [dependents, setDependents] = useState([]);
    const [activeTab, setActivetab] = useState("1");
    const [showModalDep, setShowModalDep] = useState(false);
    const [selectedDep, setSelectedDep] = useState({});
    const {personId} = useParams();
    const {user} = useAuth();
    const {Id, OwnerId} = {...user};
    const breakpoint = useMediaQuery();
    const navigate = useNavigate();
    const {
        values,
        isValid,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setValues
    } = useFormik({
        initialValues: {
            id: '',
            uuid: '',
            taxNumber: '',
            fullName: '',
            identity: '',
            identityIssuer: '',
            identityState: '',
            identityDate: '',
            birthDate: '',
            nationality: '',
            birthCity: '',
            birthState: '',
            gender: '',
            motherFullName: '',
            fatherFullName: '',
            maritalStatusId: '',
            spouseFullName: '',
            professionId: '',
            occupationId: '',
            occupyTypeId: '',
            educationId: '',
            employerCompany: '',
            addressZipcode: '',
            addressStreet: '',
            addressNumber: '',
            addressComplement: '',
            addressNeighborhood: '',
            addressCity: '',
            addressState: '',
            phone: '',
            phoneAlt: '',
            email: '',
            companyId: '',
            projectId: '',
            contractDate: today,
            effectiveDate: '',
            firstDueDate: '',
            dueDate: '',
            dependents: [],
            fileProofOfResidence: [],
            fileCPF: [],
            fileDriveLicenseFront: [],
            fileDriveLicenseBack: [],
            fileIdentityFront: [],
            fileIdentityBack: [],
            contractStatus: false,
            sendCardId: false,
        },
        validationSchema: Yup.object({
            taxNumber: Yup.string().required('Preencha este campo'),
            fullName: Yup.string().required('Preencha este campo'),
            identity: Yup.string().required('Preencha este campo'),
            birthDate: Yup.string().required('Preencha este campo'),
            nationality: Yup.string().required('Preencha este campo'),
            birthCity: Yup.string().required('Preencha este campo'),
            birthState: Yup.string().required('Preencha este campo'),
            gender: Yup.string().required('Preencha este campo'),
            motherFullName: Yup.string().required('Preencha este campo'),
            addressZipcode: Yup.string().required('Preencha este campo'),
            addressStreet: Yup.string().required('Preencha este campo'),
            addressNeighborhood: Yup.string().required('Preencha este campo'),
            addressCity: Yup.string().required('Preencha este campo'),
            addressState: Yup.string().required('Preencha este campo'),
            phone: Yup.string().required('Preencha este campo'),
            email: Yup.string().email('E-mail inválido').required('Preencha este campo'),
            projectId: Yup.number().required('Preencha este campo'),
            contractDate: Yup.date().required('Preencha este campo'),
            effectiveDate: Yup.date().required('Preencha este campo'),
            firstDueDate: Yup.date().required('Preencha este campo'),
            dueDate: Yup.number().required('Preencha este campo'),
        }),
        onSubmit: async (v) => {
            try {

                setSaving(true);
                const confirm = await Swal.fire({
                    title: 'Salvar?',
                    text: 'Deseja salvar as alterações deste registro?',
                    confirmButtonText: 'Sim',
                    showCancelButton: true,
                    cancelButtonText: 'Não',
                });
                if (!confirm.isConfirmed) return;
                const url = personId ? `assinante/${personId}` : 'assinante';
                const method = personId ? 'put' : 'post';
                const d = {
                    brokerId: Id,
                    sellerId: OwnerId,
                    fullName: v.fullName,
                    taxNumber: v.taxNumber,
                    identity: v.identity,
                    identityIssuer: v.identityIssuer,
                    identityState: v.identityState,
                    identityDate: v.identityDate,
                    employerCompany: v.employerCompany,
                    birthDate: v.birthDate,
                    gender: v.gender,
                    motherFullName: v.motherFullName,
                    fatherFullName: v.fatherFullName,
                    maritalStatusId: v.maritalStatusId,
                    spouseFullName: v.spouseFullName,
                    nationality: v.nationality,
                    birthCity: v.birthCity,
                    birthState: v.birthState,
                    occupationId: v.occupationId,
                    occupyTypeId: v.occupyTypeId,
                    educationId: v.educationId,
                    professionId: v.professionId,
                    projectId: v.projectId,
                    companyId: v.companyId,
                    dueDate: v.dueDate,
                    effectiveDate: v.effectiveDate,
                    firstDueDate: v.firstDueDate,
                    contractDate: v.contractDate,
                    phone: v.phone,
                    email: v.email,
                    phoneAlt: v.phoneAlt,
                    address: {
                        description: 'Endereço principal',
                        zipCode: v.addressZipcode,
                        street: v.addressStreet,
                        number: v.addressNumber,
                        complement: v.addressComplement,
                        neighborhood: v.addressNeighborhood,
                        city: v.addressCity,
                        state: v.addressState,
                        country: 'BR',
                    },
                    documents: [],
                    sendCardId: v.sendCardId
                }

                if (v.fileCPF[0]?.base64) {
                    d.documents.push({
                        type: 1,
                        base64: v.fileCPF[0].base64,
                        description: "CPF",
                    })
                }
                if (v.fileProofOfResidence[0]?.base64) {
                    d.documents.push({
                        type: 2,
                        base64: v.fileProofOfResidence[0].base64,
                        description: "Comprovante de Endereço",
                    })
                }
                if (v.fileDriveLicenseFront[0]?.base64) {
                    d.documents.push({
                        type: 3,
                        base64: v.fileDriveLicenseFront[0].base64,
                        description: "CNH - Frente",
                    })
                }
                if (v.fileDriveLicenseBack[0]?.base64) {
                    d.documents.push({
                        type: 16,
                        base64: v.fileDriveLicenseBack[0].base64,
                        description: "CNH - Verso",
                    })
                }
                if (v.fileIdentityFront[0]?.base64) {
                    d.documents.push({
                        type: 0,
                        base64: v.fileIdentityFront[0].base64,
                        description: "Identidade - Frente",
                    })
                }
                if (v.fileIdentityBack[0]?.base64) {
                    d.documents.push({
                        type: 7,
                        base64: v.fileIdentityBack[0].base64,
                        description: "Identidade - Verso",
                    })
                }
                if (v.contractStatus) {
                    d.contractStatus = 3;
                }
                const response = await httpClient.request({url, method, data: d});

                if (method === 'post') {
                    if (response.data.toString().toLowerCase().includes("error")) {

                        navigate('/persons');
                    } else {
                        let url = '/persons';

                        if (response.data.data) {
                            const addDeps = await Swal.fire({
                                title: 'Dependentes?',
                                text: 'Deseja incluir dependentes',
                                confirmButtonText: 'Sim',
                                showCancelButton: true,
                                cancelButtonText: 'Não',
                            });

                            if (addDeps.isConfirmed) {
                                url = `/persons/data/${response.data.data.UUID ? response.data.data.UUID : response.data.data.SubscriptionId}?opentab=3`;
                            }
                            navigate(url);
                        } else {
                            navigate(url);
                        }
                    }
                    toast.success('Registro salvo com sucesso', {
                        ...toastOptions
                    });
                } else {
                    navigate('/persons');
                }
            } catch (e) {
                const {errors} = {...e.response.data};
                if (typeof errors === 'object') {
                    for (const key in errors) {
                        toast.error(errors[key], {...toastOptions});
                    }
                }
            } finally {
                setSaving(false);
            }
        }
    });

    const handleDropChange = (acceptedFiles, fieldName) => {
        setFieldValue(fieldName,
            acceptedFiles.map((file) => {
                const {type} = file;
                const mime = type.split('/');
                const reader = new FileReader();
                reader.addEventListener('load', e => {
                    file.base64 = e.target.result;
                });
                reader.readAsDataURL(file);
                return Object.assign(file, {
                    preview: mime[0] === 'image' ? URL.createObjectURL(file) : 'https://curate.nd.edu/downloads/und:9880vq30s7m/thumbnail',
                })
            })
        );
    };

    const _queryCPF = async () => {
        try {
            if (!values.taxNumber) return;
            setLoading(true)
            const res = await httpClient.get(`extra/consultar/cpf/${onlyNumber(values.taxNumber)}/?data=01/01/2001`);
            const {
                nome_da_pf,
                data_nascimento,
            } = {...res.data.data};
            await setFieldValue('fullName', nome_da_pf, false);
            await setFieldValue('birthDate', parseToISODate(data_nascimento), false);
        } catch (e) {
            const {errors} = {...e.response.data};
            if (typeof errors === 'object') {
                for (const key in errors) {
                    toast.error(errors[key], {
                        ...toastOptions
                    });
                }
                if (e.response.status.toString().includes("40")) {
                    toast.error("Confira se a Data de Nascimento e o número de CPF estão corretos", {...toastOptions});
                }
            }
        } finally {
            setLoading(false);
        }
    }

    const _searchAddress = async () => {
        await zipCode(values.addressZipcode,
            () => setLoadingAddress(true),
            async (d) => {
                const {
                    logradouro,
                    complemento,
                    bairro,
                    localidade,
                    uf,
                } = {...d};
                await setFieldValue('addressStreet', logradouro, false);
                await setFieldValue('addressComplement', complemento, false);
                await setFieldValue('addressNeighborhood', bairro, false);
                await setFieldValue('addressCity', localidade, false);
                await setFieldValue('addressState', uf, false);
                setLoadingAddress(false);
            },
            (e) => {
                if (typeof e === 'object') {
                    for (const key in e) {
                        toast.error(e[key], {
                            ...toastOptions
                        });
                    }
                }
            });
    }

    const fetchStates = () => {
        const options = [];
        for (const key in states) {
            options.push({label: states[key], value: states[key]});
        }
        setStatesList(options);
    }
    const fetchGender = () => {
        const options = [];
        for (const key in genres) {
            options.push({label: genres[key], value: genres[key][0]});
        }
        setGenresList(options);
    }

    const fetchMaritalStatus = useCallback(async () => {
        try {
            const res = await httpClient.get('/extra/estado-civil');
            const {data} = res.data;
            const mS = [];
            for (const key in data) {
                const {Id, Description} = data[key];
                mS.push({
                    label: Description,
                    value: Id
                })
            }
            setMaritalStatusList(mS);
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
        }
    }, []);

    const fetchProject = useCallback(async () => {
        try {
            setLoadingProjects(true)
            const res = await httpClient.get('/comercial/projetos', {
                params: {
                    OwnerId: OwnerId,
                    status: 1
                }
            });
            const {data} = res.data;
            const prj = [];
            for (const key in data) {
                const {Id, Description} = data[key];
                prj.push({label: Description, value: Id});
            }
            setProjectsList(prj);
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
        } finally {
            setLoadingProjects(false);
        }
    }, [OwnerId]);

    const fetchCompanies = useCallback(async () => {
        try {
            setLoadingCompanies(true);
            const res = await httpClient.get('/credenciamento/empresas', {
                params: {
                    sellerId: user?.PersonId
                }
            });
            const {data} = res.data;
            const comp = [];
            for (const key in data) {
                const {Id, BusinessName} = data[key];
                comp.push({label: BusinessName, value: Id});
            }
            setCompaniesList(comp);

        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
        } finally {
            setLoadingCompanies(false);
        }
    }, [user?.PersonId]);

    const fetchPerson = useCallback(async () => {
        try {
            if (!personId) return;
            setLoading(true);
            const res = await httpClient.get(`/assinante/${personId}`);
            const {data} = {...res.data};

            const {
                Id,
                UUID,
                FullName,
                TaxNumber,
                Identity,
                Genre,
                BirthDate,
                CompanyId,
                ContractDate,
                ContractStatus,
                ProjectId,
                FirstDueDate,
                DueDate,
                EffectiveDate,
                BirthCity,
                BirthState,
                Nationality,
                MotherFullName,
                FatherFullName,
                MaritalStatusId,
                SpouseFullName,
                IdentityIssuer,
                IdentityDate,
                IdentityState,
                ProfessionId,
                Profession,
                OccupyTypeId,
                OccupationId,
                EducationId,
                EmployerCompany,
                Address,
                Contacts,
                SendCardId,
                Documents,
            } = {...data};
            const {
                ZipCode,
                Street,
                Number,
                Complement,
                Neighborhood,
                City,
                State,
            } = {...Address};
            const {
                Phone,
                PhoneAlt,
                Email
            } = {...Contacts};

            const FileCPF = [];
            const FileProofOfResidence = [];
            const FileDriveLicenseFront = [];
            const FileDriveLicenseBack = [];
            const FileIdentityFront = [];
            const FileIdentityBack = [];
            for (const index in Documents) {
                const {Src, Type, FileTypeDescription} = {...Documents[index]};
                switch (Type) {
                    case 1:
                        FileCPF.push({preview: Src, name: FileTypeDescription});
                        break;
                    case 2:
                        FileProofOfResidence.push({preview: Src, name: FileTypeDescription});
                        break;
                    case 3:
                        FileDriveLicenseFront.push({preview: Src, name: FileTypeDescription});
                        break;
                    case 16:
                        FileDriveLicenseBack.push({preview: Src, name: FileTypeDescription});
                        break;
                    case 0:
                        FileIdentityFront.push({preview: Src, name: FileTypeDescription});
                        break;
                    case 7:
                        FileIdentityBack.push({preview: Src, name: FileTypeDescription});
                        break;
                    default:
                }
            }
            await setValues({
                "id": Id,
                "uuid": UUID,
                "taxNumber": TaxNumber || '',
                "fullName": FullName || '',
                "identity": Identity || '',
                "birthDate": BirthDate || '',
                "nationality": Nationality || '',
                "birthCity": BirthCity || '',
                "birthState": BirthState || '',
                "gender": Genre || '',
                "motherFullName": MotherFullName || '',
                "fatherFullName": FatherFullName || '',
                "maritalStatusId": MaritalStatusId?.toString() || '',
                "spouseFullName": SpouseFullName || '',
                "identityIssuer": IdentityIssuer || '',
                "identityDate": IdentityDate || '',
                "identityState": IdentityState || '',
                "professionId": ProfessionId || '',
                "occupationId": OccupationId || '',
                "occupyTypeId": OccupyTypeId || '',
                "educationId": EducationId || '',
                "employerCompany": EmployerCompany || '',
                "addressZipcode": ZipCode || '',
                "addressStreet": Street || '',
                "addressNumber": Number || '',
                "addressComplement": Complement || '',
                "addressNeighborhood": Neighborhood || '',
                "addressCity": City || '',
                "addressState": State || '',
                "phone": Phone || '',
                "phoneAlt": PhoneAlt || '',
                "email": Email || '',
                "companyId": CompanyId || '',
                "projectId": ProjectId || '',
                "contractDate": ContractDate || '',
                "contractStatus": ContractStatus,
                "effectiveDate": EffectiveDate || '',
                "firstDueDate": FirstDueDate || '',
                "dueDate": DueDate || '',
                "fileProofOfResidence": FileProofOfResidence,
                "fileCPF": FileCPF,
                "fileDriveLicenseFront": FileDriveLicenseFront,
                "fileDriveLicenseBack": FileDriveLicenseBack,
                "fileIdentityFront": FileIdentityFront,
                "fileIdentityBack": FileIdentityBack,
                "sendCardId": SendCardId || false
            });
            fetchProfessionOptions(Profession)
            setCompanyInput(CompanyId ? false : true)
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
        } finally {
            setLoading(false);
        }
    }, [personId, setValues]);

    const fetchCompany = useCallback(async () => {
        try {
            if (!values.companyId) return;
            const res = await httpClient.get(`credenciamento/empresa/${values.companyId}`);
            const {data} = {...res.data};
            await setValues({
                ...values,
                projectId: data.ProjectId,
                dueDate: data.DueDate,
            });
        } catch (e) {
        }
    }, [values.companyId]);

    const fetchIdentityIssuers = useCallback(async () => {
        try {
            const res = await httpClient.get('/extra/expedidores?description=');
            const {data} = res.data;
            const issList = [];
            for (const key in data) {
                const {Acronym} = data[key];
                issList.push({
                    label: Acronym,
                    value: Acronym
                })
            }
            setIdentityIssList(issList);
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
        }
    }, []);

    const fetchStateList = useCallback(async () => {
        try {
            const res = await httpClient.get('/extra/uf');
            const {data} = res.data;
            const stt = [];
            for (const key in data) {
                const {Short} = data[key];
                stt.push({
                    label: Short,
                    value: Short
                })
            }
            setStateList(stt);
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
        }
    }, []);

    const fetchEducation = useCallback(async () => {
        try {
            const res = await httpClient.get('extra/escolaridade');
            const {data} = res.data;
            const educ = [];
            for (const key in data) {
                const {Id, Description} = data[key];
                educ.push({
                    label: Description,
                    value: Id
                })
            }
            setEducationList(educ);
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
        }
    }, []);

    const fetchProfessionOptions = async (inputValue, callback) => {
        try {
            const resp = await httpClient.get(`/extra/profissoes?description=${inputValue}`);
            const {data} = resp.data;

            const professionOptions = data.map(item => ({
                label: item.Description,
                value: item.Id
            }));

            setProfessionList(professionOptions);
            if (callback) {
                callback(professionOptions);
            }
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
            return [];
        }
    };

    const fetchOccupation = useCallback(async () => {
        try {
            const res = await httpClient.get('/extra/ocupacoes');
            const {data} = res.data;
            const occ = [];
            for (const key in data) {
                const {Id, Description} = data[key];
                occ.push({
                    label: Description,
                    value: Id
                })
            }
            setOccupationList(occ);
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
        }
    }, []);

    const fetchOccupationType = useCallback(async () => {
        try {
            const res = await httpClient.get('/extra/areas-atuacao');
            const {data} = res.data;
            const occT = [];
            for (const key in data) {
                const {Id, Description} = data[key];
                occT.push({
                    label: Description,
                    value: Id
                })
            }
            setOccupationTypeList(occT);
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
        }
    }, []);

    const DEFAULT = {
        depId: null,
        holderId: personId,
        fullName: '',
        degreeOfRelatedness: '',
        taxNumber: '',
        birthDate: '',
        nationality: '',
        birthCity: '',
        birthState: '',
        gender: '',
        motherFullName: '',
        fatherFullName: '',
        phone: '',
        email: '',
        address: ''
    };

    const fetchDependents = useCallback(async () => {
        try {
            if (!personId) return false;
            await httpClient.get(`titular/${personId}/dependentes`).then((resp) => {
                return resp.data.data
            }).then(data => {
                const deps = [];
                for (const k in data) {
                    const {
                        Id,
                        DegreeOfRelatedness,
                        FullName,
                        TaxNumber,
                        Nationality,
                        BirthDate,
                        BirthCity,
                        BirthState,
                        Gender,
                        MotherFullName,
                        FatherFullName,
                        email,
                        phone,
                    } = {...data[k]};
                    deps.push({
                        depHolderId: personId,
                        depId: Id,
                        depDegreeOfRelatedness: DegreeOfRelatedness,
                        depFullName: FullName,
                        depTaxNumber: TaxNumber,
                        depNationality: Nationality,
                        BirthDate: BirthDate,
                        BirthCity: BirthCity,
                        depBirthState: BirthState,
                        depGender: Gender,
                        depMotherFullName: MotherFullName,
                        depFatherFullName: FatherFullName,
                        depEmail: email,
                        depPhone: phone
                    });
                }
                setDependents(deps);
            });
        } catch (e) {
        }
    }, [personId]);

    const deleteDependent = async (row, newData) => {
        try {
            setLoading(true);
            await httpClient.delete(`titular/${personId}/dependente/${row.depId}`);
            await setFieldValue('dependents', newData);
            await fetchDependents();
        } catch (err) {
            const {errors} = {...err.response.data};
            if (typeof errors === 'object') {
                for (const key in errors) {
                    toast.error(errors[key], {
                        ...toastOptions
                    });
                }
            } else {
                toast.error(err.message(), {
                    ...toastOptions
                });
            }
        } finally {
            setLoading(false);
        }
    }

    const _handleSendAnalisys = async (e) => {
        let {checked} = {...e.target};
        if (checked) {
            await Swal.fire({
                title: "ATENÇÃO!!!",
                text: "Ao marcar esta opção, o dados serão enviados para análise de validação e só voltará a poder ser editado após pendenciamento por parte do setor de cadastro!",
                confirmButtonText: "OK, estou ciente!",
            });
        }
        await setFieldValue('contractStatus', checked)
    }

    const _handleDependentForm = (row) => {
        setShowModalDep(true);
        setSelectedDep(row);
    }

    const _selectedValue = (list, value) => {
        return list.filter((opt) => {
            return (opt?.value.toString() === value?.toString())
        })
    };

    useEffect(() => {
        fetchStates();
        fetchGender();
        fetchMaritalStatus().then();
        fetchProject().then();
        fetchPerson().then();
        fetchCompanies().then();
        fetchStateList().then();
        fetchIdentityIssuers().then();
        fetchOccupationType().then();
        fetchOccupation().then();
        fetchEducation().then();
        fetchDependents().then();
    }, [
        fetchProject,
        fetchPerson,
        fetchCompanies,
        fetchStateList,
        fetchIdentityIssuers,
        fetchMaritalStatus,
        fetchOccupationType,
        fetchOccupation,
        fetchEducation,
        fetchDependents
    ]);

    useEffect(() => {
        if (window.location.search) {
            const params = new URLSearchParams(window.location.search);
            setActivetab(params.get("opentab"));
        }
        fetchCompany()
    }, [values.companyId]);


    return (
        <div>
            <BlockHead size="sm">
                <Head title="Assinante"/>
                <BlockBetween>
                    <BlockHeadContent>
                        <BlockTitle page>Cadastro de Assinantes</BlockTitle>
                        <BlockDes className={'text-soft'}>Dados cadastrais de assinante.</BlockDes>
                    </BlockHeadContent>
                    <BlockHeadContent>
                        <div className={'toggle-wrap nk-block-tools-toggle'}>
                            <Button
                                className={`btn-icon btn-trigger toggle-expand me-n1 ${breakpoint === 'xs' ? 'active' : ''}`}
                                onClick={() => setToggleMenu(!toggleMenu)}>
                                <Icon name="menu-alt-r"></Icon>
                            </Button>
                            <div className="toggle-expand-content"
                                 style={{display: toggleMenu ? "block" : "none"}}>
                                <ul className="nk-block-tools g-3">
                                    <li>
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox"
                                                   className="custom-control-input"
                                                   defaultChecked={values.contractStatus === 3}
                                                   onChange={_handleSendAnalisys}
                                                   id="contractStatus"/>
                                            <label className="custom-control-label" htmlFor="contractStatus">
                                                Enviar para análise
                                            </label>
                                        </div>
                                    </li>
                                    <li className="nk-block-tools-opt">
                                        <Button
                                            type={'submit'}
                                            form={'form'}
                                            color={'primary'}
                                            className={'btn-dim'}
                                            disabled={!isValid || (isSaving || isLoading)}>
                                            {isSaving ? <Spinner size={'sm'}/> : <Icon name={'check'}/>}
                                            <span>SALVAR</span>
                                        </Button>
                                    </li>
                                    <li className={'nk-block-tool-opt'}>
                                        <Button
                                            type={'button'}
                                            color="light"
                                            outline
                                            onClick={() => navigate("/persons")}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </BlockHeadContent>
                </BlockBetween>
            </BlockHead>
            <form id={'form'} onSubmit={handleSubmit}>
                <Row className={'g-3 my-4'}>
                    <Col lg={3}>
                        <OutlinedInput
                            id={'taxNumber'}
                            name={'taxNumber'}
                            label={'CPF'}
                            title={"Número de CPF"}
                            size={'xl'}
                            mask={CPF}
                            required
                            value={values.taxNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.taxNumber && touched.taxNumber}
                            error={errors.taxNumber}
                            iconRight={
                                <Button
                                    type={'button'}
                                    className={'btn-icon me-2'}
                                    onClick={_queryCPF}>
                                    {isLoading ? <Spinner size={'sm'}/> : <Icon name={'search'}/>}
                                </Button>
                            }
                        />
                    </Col>
                    <Col lg={6}>
                        <OutlinedInput
                            id={'fullName'}
                            name={'fullName'}
                            label={'Nome completo'}
                            size={'xl'}
                            required
                            value={values.fullName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.fullName && touched.fullName}
                            error={errors.fullName}/>
                    </Col>
                    <Col lg={3}>
                        <OutlinedInput
                            id={'birthDate'}
                            name={'birthDate'}
                            type={'date'}
                            label={'Data de nascimento'}
                            size={'xl'}
                            required
                            value={values.birthDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.birthDate && touched.birthDate}
                            error={errors.birthDate}/>
                    </Col>
                    <Col lg={3}>
                        <OutlinedInput
                            id={'identity'}
                            name={'identity'}
                            label={'RG'}
                            size={'xl'}
                            required
                            value={values.identity}
                            maxLength={11}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.identity && touched.identity}
                            error={errors.identity}/>
                    </Col>
                    <Col lg={4}>
                        <OutlinedSelect
                            id={'identityIssuer'}
                            name={'identityIssuer'}
                            label={'Órgão Emissor'}
                            size={'xl'}
                            options={identityIssList}
                            value={_selectedValue(identityIssList, values.identityIssuer)}
                            onChange={(opt) => setFieldValue('identityIssuer', opt?.value)}
                            onBlur={handleBlur}
                            isInvalid={errors.identityIssuer && touched.identityIssuer}
                            error={errors.identityIssuer}
                        />
                    </Col>
                    <Col lg={2}>
                        <OutlinedSelect
                            id={'identityState'}
                            name={'identityState'}
                            label={'UF emissora'}
                            size={'xl'}
                            placeholder={'Selecione'}
                            options={stateList}
                             value={_selectedValue(stateList, values.identityState)}
                            onChange={(opt) => setFieldValue('identityState', opt?.value)}
                            onBlur={handleBlur}
                            isInvalid={errors.identityState && touched.identityState}
                            error={errors.identityState}
                        />
                    </Col>
                    <Col lg={3}>
                        <OutlinedInput
                            id={'identityDate'}
                            name={'identityDate'}
                            type={'date'}
                            label={'Data de emissão'}
                            size={'xl'}
                            value={values.identityDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.identityDate && touched.identityDate}
                            error={errors.identityDate}/>
                    </Col>
                    <Col lg={3}>
                        <OutlinedInput
                            id={'nationality'}
                            name={'nationality'}
                            label={'Nacionalidade'}
                            size={'xl'}
                            required
                            value={values.nationality}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.nationality && touched.nationality}
                            error={errors.nationality}/>
                    </Col>
                    <Col lg={4}>
                        <OutlinedInput
                            id={'birthCity'}
                            name={'birthCity'}
                            label={'Cidade natal'}
                            size={'xl'}
                            required
                            value={values.birthCity}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.birthCity && touched.birthCity}
                            error={errors.birthCity}/>
                    </Col>
                    <Col lg={2}>
                        <OutlinedSelect
                            id={'birthState'}
                            name={'birthState'}
                            label={'UF natal*'}
                            size={'xl'}
                            placeholder={'Selecione'}
                            required
                            options={statesList}
                            value={_selectedValue(statesList, values.birthState)}
                            onChange={(opt) => setFieldValue('birthState', opt?.value)}
                            onBlur={handleBlur}
                            isInvalid={errors.birthState && touched.birthState}
                            error={errors.birthState}/>
                    </Col>
                    <Col lg={3}>
                        <OutlinedSelect
                            id={'gender'}
                            name={'gender'}
                            label={'Gênero*'}
                            placeholder={'Selecione'}
                            size={'xl'}
                            required
                            options={genresList}
                             value={_selectedValue(genresList, values.gender)}
                            onChange={(opt) => setFieldValue('gender', opt?.value)}
                            onBlur={handleBlur}
                            isInvalid={errors.gender && touched.gender}
                            error={errors.gender}/>
                    </Col>
                    <Col lg={6}>
                        <OutlinedInput
                            id={'motherFullName'}
                            name={'motherFullName'}
                            label={'Nome da mãe'}
                            size={'xl'}
                            required
                            value={values.motherFullName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.motherFullName && touched.motherFullName}
                            error={errors.motherFullName}/>
                    </Col>
                    <Col lg={6}>
                        <OutlinedInput
                            id={'fatherFullName'}
                            name={'fatherFullName'}
                            label={'Nome da pai'}
                            size={'xl'}
                            value={values.fatherFullName}
                            onChange={handleChange}
                            onBlur={handleBlur}/>
                    </Col>
                    <Col lg={3}>
                        <OutlinedSelect
                            id={'maritalStatusId'}
                            name={'maritalStatusId'}
                            label={'Estado Civil'}
                            size={'xl'}
                            placeholder={'Selecione'}
                            options={maritalStatusList}
                             value={_selectedValue(maritalStatusList, values.maritalStatusId)}
                            onChange={(opt) => setFieldValue('maritalStatusId', opt?.value)}
                            onBlur={handleBlur}/>
                    </Col>
                    <Col lg={9}>
                        <OutlinedInput
                            id={'spouseFullName'}
                            name={'spouseFullName'}
                            label={'Nome do cônjuge'}
                            size={'xl'}
                            value={values.spouseFullName}
                            onChange={handleChange}
                            onBlur={handleBlur}/>
                    </Col>
                    <Col lg={6}>
                        <OutlinedSelect
                            id={'educationId'}
                            name={'educationId'}
                            label={'Escolaridade'}
                            size={'xl'}
                            placeholder={'Selecione'}
                            options={educationList}
                            value={_selectedValue(educationList, values.educationId)}
                            onChange={(opt) => setFieldValue('educationId', opt?.value)}
                            onBlur={handleBlur}
                        />
                    </Col>
                    <Col lg={6}>
                        <OutlinedAsyncSelect
                            id={'professionId'}
                            name={'professionId'}
                            label={'Profissão'}
                             value={_selectedValue(professionList, values.professionId)}
                            placeholder='Pesquisar'
                            size='xl'
                            cacheOptions
                            defaultOptions
                            loadOptions={fetchProfessionOptions}
                            options={professionList}
                            onChange={(opt) => setFieldValue('professionId', opt?.value)}
                            onBlur={handleBlur}
                        />
                    </Col>
                    <Col lg={6}>
                        <OutlinedSelect
                            id={'occupationId'}
                            name={'occupationId'}
                            label={'Ocupação'}
                            size={'xl'}
                            placeholder={'Selecione'}
                            options={occupationList}
                             value={_selectedValue(occupationList, values.occupationId)}
                            onChange={(opt) => setFieldValue('occupationId', opt?.value)}
                            onBlur={handleBlur}
                        />
                    </Col>
                    <Col lg={6}>
                        <OutlinedSelect
                            id={'occupyTypeId'}
                            name={'occupyTypeId'}
                            label={'Área de atuação'}
                            size={'xl'}
                            placeholder={'Selecione'}
                            options={occupationTypeList}
                             value={_selectedValue(occupationTypeList, values.occupyTypeId)}
                            onChange={(opt) => setFieldValue('occupyTypeId', opt?.value)}
                            onBlur={handleBlur}
                        />
                    </Col>
                    <Col size={4} className={'align-center'}>
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" defaultChecked={companyInput}
                                   id="customSwitch2"
                                   onChange={() => {
                                       setCompanyInput(prevState => !prevState);
                                       setFieldValue('companyId', '');
                                       setFieldValue('projectId', '');
                                   }}/>
                            <label className="custom-control-label" htmlFor="customSwitch2">
                                Informar empresa manualmente</label>
                        </div>
                    </Col>
                    {!companyInput ? (
                        <Col lg={8}>
                            <OutlinedSelect
                                id={'companyId'}
                                name={'companyId'}
                                label={'Empresa vinculada'}
                                size={'xl'}
                                loading={isLoadingCompanies}
                                placeholder={'Selecione'}
                                isDisabled={companyInput === true}
                                defaultValue={values.companyId}
                                options={companiesList}
                                value={_selectedValue(companiesList, values.companyId)}
                                onChange={(opt) => setFieldValue('companyId', opt?.value)}
                                onBlur={handleBlur}
                                isInvalid={errors.companyId && touched.companyId}
                                error={errors.companyId}/>
                        </Col>
                    ) : (
                        <Col lg={8}>
                            <OutlinedInput
                                id={'employerCompany'}
                                name={'employerCompany'}
                                label={'Nome Empresa Empregadora'}
                                size={'xl'}
                                value={values.employerCompany}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Col>
                    )}
                </Row>
                <h6 className="mb-4">
                    Informações sobre o produto contratado, vencimento e vigência.
                </h6>
                <Row className={'g-3 mb-4'}>
                    <Col size={12}>
                        <OutlinedSelect
                            id={'projectId'}
                            name={'projectId'}
                            label={'Produto contratado*'}
                            required
                            loading={isLoadingProjects}
                            placeholder={'Selecione'}
                            options={projectsList}
                            size={'xl'}
                            defaultValue={values.projectId}
                            value={_selectedValue(projectsList, values.projectId)}
                            onChange={(opt) => setFieldValue('projectId', opt?.value)}
                            onBlur={handleBlur}
                            isDisabled={values.companyId}
                            isInvalid={errors.projectId && touched.projectId}
                            error={errors.projectId}/>
                    </Col>
                    <Col lg={3}>
                        <OutlinedInput
                            id={'contractDate'}
                            name={'contractDate'}
                            type={'date'}
                            label={'Data de assinatura'}
                            size={'xl'}
                            required
                            value={values.contractDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.contractDate && touched.contractDate}
                            error={errors.contractDate}/>
                    </Col>
                    <Col lg={3}>
                        <OutlinedInput
                            id={'effectiveDate'}
                            name={'effectiveDate'}
                            type={'date'}
                            label={'Data da vigência'}
                            size={'xl'}
                            required
                            value={values.effectiveDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.effectiveDate && touched.effectiveDate}
                            error={errors.effectiveDate}/>
                    </Col>
                    <Col lg={3}>
                        <OutlinedInput
                            id={'firstDueDate'}
                            name={'firstDueDate'}
                            type={'date'}
                            label={'Primeiro Vencimento'}
                            size={'xl'}
                            required
                            value={values.firstDueDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.effectiveDate && touched.effectiveDate}
                            error={errors.effectiveDate}/>
                    </Col>
                    <Col lg={3}>
                        <OutlinedSelect
                            id={'dueDate'}
                            name={'dueDate'}
                            label={'Dia do vencimento*'}
                            size={'xl'}
                            placeholder={'Selecione'}
                            required
                            options={dueDates}
                            value={_selectedValue(dueDates, values.dueDate)}
                            onChange={(opt) => setFieldValue('dueDate', opt?.value)}
                            onBlur={handleBlur}
                            isDisabled={values.companyId}
                            isInvalid={errors.dueDate && touched.dueDate}
                            error={errors.dueDate}/>
                    </Col>
                    <Col size={12}>
                        <div className="custom-control custom-switch">
                            <input
                                id="sendCardId"
                                type="checkbox"
                                className="custom-control-input"
                                checked={values.sendCardId}
                                onChange={(e) => setFieldValue('sendCardId', e.currentTarget.checked)}
                            />
                            <label className="custom-control-label"
                                   htmlFor="sendCardId">
                                Emitir carteirinha para beneficiários e dependentes
                            </label>
                        </div>
                    </Col>
                </Row>
                <Nav tabs className={'mb-2'}>
                    <NavItem>
                        <NavLink
                            tag="a"
                            href="#tab"
                            className={classnames({active: activeTab === "1"})}
                            onClick={(ev) => {
                                ev.preventDefault();
                                setActivetab("1");
                            }}
                        >
                            <Icon name="location"/> <span>Endereço</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            tag="a"
                            href="#tab"
                            className={classnames({active: activeTab === "2"})}
                            onClick={(ev) => {
                                ev.preventDefault();
                                setActivetab("2");
                            }}
                        >
                            <Icon name="clip"/> <span>Documentos</span>
                        </NavLink>
                    </NavItem>
                    {personId && (
                        <NavItem>
                            <NavLink
                                tag="a"
                                href="#tab"
                                className={classnames({active: activeTab === "3"})}
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setActivetab("3");
                                }}
                            >
                                <Icon name="users"/> <span>Dependentes</span>
                                <Badge color="danger" pill>{dependents.length}</Badge>

                            </NavLink>
                        </NavItem>
                    )}
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <p>
                            Informações de endereço e contatos da empresa.
                        </p>
                        <Row className={'g-3'}>
                            <Col lg={3}>
                                <OutlinedInput
                                    mask={ZIPCODE}
                                    id={'addressZipcode'}
                                    name={'addressZipcode'}
                                    label={'CEP'}
                                    size={'xl'}
                                    required
                                    value={values.addressZipcode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.addressZipcode && touched.addressZipcode}
                                    error={errors.addressZipcode}
                                    iconRight={
                                        <Button
                                            type={'button'}
                                            className={'btn-icon me-2'}
                                            onClick={_searchAddress}>
                                            {isLoadingAddress ? <Spinner size={'sm'}/> : <Icon name={'search'}/>}
                                        </Button>
                                    }
                                />
                            </Col>
                            <Col lg={7}>
                                <OutlinedInput
                                    id={'addressStreet'}
                                    name={'addressStreet'}
                                    label={'Logradouro (Rua, Av., alameda...)'}
                                    size={'xl'}
                                    required
                                    value={values.addressStreet}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.addressStreet && touched.addressStreet}
                                    error={errors.addressStreet}/>
                            </Col>
                            <Col lg={2}>
                                <OutlinedInput
                                    id={'addressNumber'}
                                    name={'addressNumber'}
                                    label={'Número'}
                                    size={'xl'}
                                    value={values.addressNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}/>
                            </Col>
                            <Col lg={4}>
                                <OutlinedInput
                                    id={'addressComplement'}
                                    name={'addressComplement'}
                                    label={'Complemento'}
                                    size={'xl'}
                                    value={values.addressComplement}
                                    onChange={handleChange}
                                    onBlur={handleBlur}/>
                            </Col>
                            <Col lg={3}>
                                <OutlinedInput
                                    id={'addressNeighborhood'}
                                    name={'addressNeighborhood'}
                                    label={'Bairro'}
                                    size={'xl'}
                                    required
                                    value={values.addressNeighborhood}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.addressNeighborhood && touched.addressNeighborhood}
                                    error={errors.addressNeighborhood}/>
                            </Col>
                            <Col lg={3}>
                                <OutlinedInput
                                    id={'addressCity'}
                                    name={'addressCity'}
                                    label={'Cidade'}
                                    size={'xl'}
                                    required
                                    value={values.addressCity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.addressCity && touched.addressCity}
                                    error={errors.addressCity}/>
                            </Col>
                            <Col lg={2}>
                                <OutlinedSelect
                                    id={'addressState'}
                                    name={'addressState'}
                                    label={'UF'}
                                    size={'xl'}
                                    placeholder={'Selecione'}
                                    required
                                    options={statesList}
                                    value={_selectedValue(statesList, values.addressState)}
                                    onChange={(opt) => setFieldValue('addressState', opt?.value)}
                                    onBlur={handleBlur}
                                    isInvalid={errors.addressState && touched.addressState}
                                    error={errors.addressState}/>
                            </Col>
                            <Col lg={3}>
                                <OutlinedInput
                                    mask={PHONE}
                                    id={'phone'}
                                    name={'phone'}
                                    label={'Telefone principal'}
                                    size={'xl'}
                                    required
                                    minLength={10}
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.phone && touched.phone}
                                    error={errors.phone}/>
                            </Col>
                            <Col lg={3}>
                                <OutlinedInput
                                    mask={PHONE}
                                    id={'phoneAlt'}
                                    name={'phoneAlt'}
                                    label={'Telefone alternativo'}
                                    size={'xl'}
                                    value={values.phoneAlt}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Col>
                            <Col lg={6}>
                                <OutlinedInput
                                    id={'email'}
                                    name={'email'}
                                    type={'email'}
                                    label={'E-mail principal'}
                                    size={'xl'}
                                    required
                                    minLength={5}
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.email && touched.email}
                                    error={errors.email}/>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <p>
                            Anexar arquivos das documentações exigidas para contratação.
                        </p>
                        <Row className={'g-3'}>
                            <Col lg={4} md={6}>
                                <label htmlFor={'fileCPF'} className="form-label">
                                    Cópia do CPF<span className={'text-danger'}>*</span>
                                </label>
                                <Dropzone
                                    {...dropzoneDefaults}
                                    onDrop={
                                        (acceptedFiles) => handleDropChange(acceptedFiles, 'fileCPF')
                                    }>
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                                                <input id={'fileCPF'} {...getInputProps()} />
                                                {values.fileCPF.length === 0 && (
                                                    <div className="dz-message">
                                                        <span className="dz-message-text">Arraste e solte</span>
                                                        <span className="dz-message-or">ou</span>
                                                        <Button type={'button'} color="primary">SELECIONE</Button>
                                                    </div>
                                                )}
                                                {values.fileCPF.map((file, index) => (
                                                    <div key={index}
                                                         className="dz-preview dz-processing dz-image-preview dz-error dz-complete justify-content-center d-flex">
                                                        <div className="dz-image">
                                                            <img src={file.preview} alt="preview"/>

                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </Col>
                            <Col lg={4} md={6}>
                                <label htmlFor={'fileDriveLicenseFront'} className="form-label">
                                    CNH (Frente)<span className={'text-danger'}>*</span>
                                </label>
                                <Dropzone
                                    {...dropzoneDefaults}
                                    onDrop={
                                        (acceptedFiles) => handleDropChange(acceptedFiles, 'fileDriveLicenseFront')
                                    }>
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                                                <input id={'fileDriveLicenseFront'} {...getInputProps()} />
                                                {values.fileDriveLicenseFront.length === 0 && (
                                                    <div className="dz-message">
                                                        <span className="dz-message-text">Arraste e solte</span>
                                                        <span className="dz-message-or">ou</span>
                                                        <Button type={'button'} color="primary">SELECIONE</Button>
                                                    </div>
                                                )}
                                                {values.fileDriveLicenseFront.map((file, index) => (
                                                    <div key={index}
                                                         className="dz-preview dz-processing dz-image-preview dz-error dz-complete justify-content-center d-flex">
                                                        <div className="dz-image">
                                                            <img src={file.preview} alt="preview"/>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </Col>
                            <Col lg={4} md={6}>
                                <label htmlFor={'fileDriveLicenseBack'} className="form-label">
                                    CNH (Verso)<span className={'text-danger'}>*</span>
                                </label>
                                <Dropzone
                                    {...dropzoneDefaults}
                                    onDrop={
                                        (acceptedFiles) => handleDropChange(acceptedFiles, 'fileDriveLicenseBack')
                                    }>
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                                                <input id={'fileDriveLicenseBack'} {...getInputProps()} />
                                                {values.fileDriveLicenseBack.length === 0 && (
                                                    <div className="dz-message">
                                                        <span className="dz-message-text">Arraste e solte</span>
                                                        <span className="dz-message-or">ou</span>
                                                        <Button type={'button'} color="primary">SELECIONE</Button>
                                                    </div>
                                                )}
                                                {values.fileDriveLicenseBack.map((file, index) => (
                                                    <div key={index}
                                                         className="dz-preview dz-processing dz-image-preview dz-error dz-complete justify-content-center d-flex">
                                                        <div className="dz-image">
                                                            <img src={file.preview} alt="preview"/>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </Col>
                            <Col lg={4} md={6}>
                                <label htmlFor={'fileIdentityFront'} className="form-label">
                                    Cópia da frente do RG (Identidade)<span className={'text-danger'}>*</span>
                                </label>
                                <Dropzone
                                    {...dropzoneDefaults}
                                    onDrop={
                                        (acceptedFiles) => handleDropChange(acceptedFiles, 'fileIdentityFront')
                                    }>
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                                                <input id={'fileIdentityFront'} {...getInputProps()} />
                                                {values.fileIdentityFront.length === 0 && (
                                                    <div className="dz-message">
                                                        <span className="dz-message-text">Arraste e solte</span>
                                                        <span className="dz-message-or">ou</span>
                                                        <Button type={'button'} color="primary">SELECIONE</Button>
                                                    </div>
                                                )}
                                                {values.fileIdentityFront.map((file, index) => (
                                                    <div key={index}
                                                         className="dz-preview dz-processing dz-image-preview dz-error dz-complete justify-content-center d-flex">
                                                        <div className="dz-image">
                                                            <img src={file.preview} alt="preview"/>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </Col>
                            <Col lg={4} md={6}>
                                <label htmlFor={'fileIdentityBack'} className="form-label">
                                    Cópia do verso do RG (Identidade)<span className={'text-danger'}>*</span>
                                </label>
                                <Dropzone
                                    {...dropzoneDefaults}
                                    onDrop={
                                        (acceptedFiles) => handleDropChange(acceptedFiles, 'fileIdentityBack')
                                    }>
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                                                <input id={'fileIdentityBack'} {...getInputProps()} />
                                                {values.fileIdentityBack.length === 0 && (
                                                    <div className="dz-message">
                                                        <span className="dz-message-text">Arraste e solte</span>
                                                        <span className="dz-message-or">ou</span>
                                                        <Button type={'button'} color="primary">SELECIONE</Button>
                                                    </div>
                                                )}
                                                {values.fileIdentityBack.map((file, index) => (
                                                    <div key={index}
                                                         className="dz-preview dz-processing dz-image-preview dz-error dz-complete justify-content-center d-flex">
                                                        <div className="dz-image">
                                                            <img src={file.preview} alt="preview"/>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </Col>
                            <Col lg={4} md={6}>
                                <label htmlFor={'fileProofOfResidence'} className="form-label">
                                    Comprovante de endereço<span className={'text-danger'}>*</span>
                                </label>
                                <Dropzone
                                    {...dropzoneDefaults}
                                    onDrop={
                                        (acceptedFiles) => handleDropChange(acceptedFiles, 'fileProofOfResidence')
                                    }>
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps({className: "dropzone upload-zone dz-clickable"})}>
                                                <input id={'fileProofOfResidence'} {...getInputProps()} />
                                                {values.fileProofOfResidence.length === 0 && (
                                                    <div className="dz-message">
                                                        <span className="dz-message-text">Arraste e solte</span>
                                                        <span className="dz-message-or">ou</span>
                                                        <Button type={'button'} color="primary">SELECIONE</Button>
                                                    </div>
                                                )}
                                                {values.fileProofOfResidence.map((file, index) => (
                                                    <div key={`${index}_${file.name}`}
                                                         className="dz-preview dz-processing dz-image-preview dz-error dz-complete justify-content-center d-flex">
                                                        <div className="dz-image">
                                                            <img src={file.preview}
                                                                 alt="preview"/>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </Col>
                        </Row>
                    </TabPane>
                    {personId && (
                        <TabPane tabId="3">
                            <p>
                                Informações cadastrais de dependentes.
                            </p>
                            <Block>
                                <Button type={'button'}
                                        color={'primary'}
                                        onClick={() => _handleDependentForm(DEFAULT)}>
                                    <Icon name={'plus'}/>
                                    <span>INCLUIR DEPENDENTE</span>
                                </Button>
                            </Block>
                            <Block>
                                <DependentsList
                                    data={dependents}
                                    isLoading={isLoadingDeps}
                                    onDelete={deleteDependent}
                                    onRowClick={_handleDependentForm}/>
                            </Block>
                            <DependentsModal isOpen={showModalDep}
                                             holderId={personId}
                                             dependentId={selectedDep?.depId}
                                             toggle={() => setShowModalDep(!showModalDep)}
                                             onSave={() => {
                                                 setShowModalDep(!showModalDep);
                                                 fetchDependents();
                                             }}/>
                        </TabPane>
                    )}
                </TabContent>
            </form>
        </div>
    )
        ;
};

export default PersonsForm;