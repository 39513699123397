import React from "react";
import {
    Block,
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    BlockDes
} from "../../components/Component";
import {Card} from "reactstrap";
import Head from "../../layout/head/Head";

const Security = () => {
    return (
        <React.Fragment>
            <Head title="Log de atividades"></Head>
            <Block>
                <BlockHead size="md">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h4">Log de atividades</BlockTitle>
                            <BlockDes>
                                <p>Registros do últimos 20 registros de atividades realizadas com suas credenciais.</p>
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Card className="card-bordered">
                </Card>
            </Block>
        </React.Fragment>
    );
};

export default Security;
