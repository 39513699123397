import {
    BlockBetween, 
    BlockHead, 
    BlockHeadContent, 
    BlockTitle, 
    BlockDes
} from "../../components/Component";
import {Link, Outlet} from "react-router-dom";
import React from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

const navData = [
    {
        link: '/profile',
        text: 'Informações'
    },
    {
        link: '/profile/security',
        text: 'Segurança'
    },
    {
        link: '/profile/activity',
        text: 'Atividades'
    },
]

const Profile = () => {
    return (
        <React.Fragment>
            <Head title="Perfil"></Head>
            <Content>
                <BlockHead size="md">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h2" className="fw-normal">Configurações da Conta</BlockTitle>
                            <BlockDes>
                                Informações cadastrais sobre sua conta na plataforma TokSaúde.
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <ul className="nk-nav nav nav-tabs">
                    {navData.map((item, index) =>
                        <li key={index}
                            className={`nav-item ${
                                window.location.pathname === `${item.link}`
                                    ? "active current-page"
                                    : ""
                            } `}
                        >
                            <Link
                                to={`${process.env.PUBLIC_URL}${item.link}`}
                                className={`nav-link
                  ${window.location.pathname === `${process.env.PUBLIC_URL}${item.link}` ? "active" : ""}
                `}
                            >
                                <span>{item.text}</span>
                            </Link>
                        </li>
                    )}
                </ul>
                <Outlet/>
            </Content>
        </React.Fragment>
    );
};

export default Profile;
