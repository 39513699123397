import React, {useState} from "react";
import UserAvatar from "../../../../components/user/UserAvatar";
import {DropdownToggle, DropdownMenu, Dropdown} from "reactstrap";
import {LinkList, LinkItem} from "../../../../components/links/Links";
import {useAuth} from "../../../../hooks/useAuth";

const User = () => {
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen((prevState) => !prevState);
    const {user, logout} = useAuth();

    return (
        <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
            <DropdownToggle
                tag="a"
                href="#toggle"
                className="dropdown-toggle"
                onClick={(ev) => {
                    ev.preventDefault();
                }}
            >
                <div className="user-toggle">
                    <UserAvatar icon="user-alt" className="sm"/>
                </div>
            </DropdownToggle>
            <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
                <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                    <div className="user-card sm">
                        <div className="user-avatar">
                            <span>{user.FullName[0]}</span>
                        </div>
                        <div className="user-info">
                            <span className="lead-text">{user.FullName}</span>
                            <span className="sub-text">{user.Email}</span>
                        </div>
                    </div>
                </div>
                <div className="dropdown-inner">
                    <LinkList>
                        <LinkItem link="/profile" icon="user-alt" onClick={toggle}>
                            Ver perfil
                        </LinkItem>
                        <LinkItem link="/profile/activity" icon="activity-alt" onClick={toggle}>
                            Log de atividades
                        </LinkItem>
                    </LinkList>
                </div>
                <div className="dropdown-inner">
                    <LinkList>
                        <LinkItem link="/" onClick={logout} icon={'signout'}>
                            Sair
                        </LinkItem>
                    </LinkList>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};

export default User;
