import React, {useState, useEffect, useCallback} from "react";
import {
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle
} from "../../components/block/Block";
import {Button, Icon} from "../../components/Component";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {toastOptions} from "../../utils/Presets";
import {Spinner} from "reactstrap";
import {useAuth} from "../../hooks/useAuth";
import {CPF} from "../../utils/Masks";
import {Col, Row} from "../../components/grid/Grid";
import {contractStatus} from "../../utils/Types";
import {useFormik} from "formik";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import useMediaQuery from "../../hooks/useMediaQuery";
import httpClient from "../../utils/HttpClient";
import OutlinedInput from "../../components/input/outlined-input/OutlinedInput";
import Pagination from "../../components/pagination/Pagination";

const PersonsList = (props) => {
    const {user} = useAuth();
    const [showFilter, setShowFilter] = useState(true)
    const navigate = useNavigate();
    const breakpoint = useMediaQuery();
    const [toggleMenu, setToggleMenu] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [rowPerPage, setRowPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecord, setTotalRecord] = useState(1);

    const handlePagination = (value) => {
        setCurrentPage(value)
    }

    const _handleNew = () => {
        navigate('new');
    }

    const fetchData = useCallback(async (val) => {
        try {
            setLoading(true);
            let params = {}
            if (val) {
                const filter = Object.entries(val).filter(([, value]) => value.trim().length > 0);
                params = {
                    ...Object.fromEntries(filter),
                };
            }
            const resp = await httpClient.get(`assinantes`, {
                params: {
                    sellerId: user.OwnerId,
                    ...params
                },
                headers: {
                    'X-Page-Size': rowPerPage,
                    'X-Page-Num': currentPage,
                }
            });
            const {data} = resp.data;
            setTotalRecord(resp.data.totalRecord);
            setData(data);
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
        } finally {
            setLoading(false);
        }
    }, [currentPage, rowPerPage, user.Profile.Id]);

    const {
        handleChange,
        handleSubmit,
        values,
    } = useFormik({
        initialValues: {
            healthInsuranceNumber: "",
            name: "",
            taxNumber: "",
            productId: ""
        },
        validateOnChange: false,
        onSubmit: fetchData
    });

    useEffect(() => {
        fetchData().then();
    }, [currentPage]);

    return (
        <React.Fragment>
            <Head title="Cadastro de Assinantes"/>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle>Cadastro de Assinantes</BlockTitle>
                            <BlockDes className="text-soft">
                                Relação de assinantes (titulares) contidas em sua carteira.
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <Button
                                    className={`btn-icon btn-trigger toggle-expand me-n1 ${breakpoint === 'xs' ? 'active' : ''}`}
                                    onClick={() => setToggleMenu(!toggleMenu)}>
                                    <Icon name="menu-alt-r"></Icon>
                                </Button>
                                <div className="toggle-expand-content"
                                     style={{display: toggleMenu ? "block" : "none"}}>
                                    <ul className="nk-block-tools">
                                        <li className="nk-block-tools-opt">
                                            <Button type={'button'}
                                                    id={'btnAdd'}
                                                    color={'primary'}
                                                    onClick={_handleNew}>
                                                <Icon name="plus"></Icon>
                                                <span>NOVO</span>
                                            </Button>
                                        </li>
                                        <li className="nk-block-tools-opt">
                                            <Button type={'button'}
                                                    id={'btnFilter'}
                                                    color={'white'}
                                                    onClick={() => {
                                                        setShowFilter(!showFilter)
                                                    }}
                                                    data-target={"#box-filter"}
                                                    data-toggle={"collapse"}
                                            >
                                                <Icon name="filter"></Icon>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <div className="card card-bordered card-stretch">

                    <Row className={`g-1 m-2 collapse ${showFilter ? "show" : ""}`} id={"box-filter"}>
                        <Col lg={2}>
                            <div className={"form-group "}>
                                <div className="form-control-wrap">
                                    <OutlinedInput
                                        id={'healthInsuranceNumber'}
                                        name={'healthInsuranceNumber'}
                                        label={'Matrícula'}
                                        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                                        value={values.healthInsuranceNumber}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className={"form-group "}>
                                <div className="form-control-wrap">
                                    <OutlinedInput
                                        id={'name'}
                                        name={'name'}
                                        type={"text"}
                                        label={'Nome'}
                                        value={values.name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className={"form-group "}>
                                <div className="form-control-wrap">
                                    <OutlinedInput
                                        id={'taxNumber'}
                                        name={'taxNumber'}
                                        mask={CPF}
                                        label={'CPF'}
                                        value={values.taxNumber}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col lg={1}>
                            <div className={"form-group h-100"}>
                                <Button onClick={handleSubmit} type="button"
                                        className={"btn btn-primary btn-block btn-dim justify-content-center"}>
                                    <Icon name={"search"}></Icon>
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <table className="table">
                        <thead>
                        <tr>
                            <th><span className="overline-title">#</span></th>
                            <th><span className="overline-title">Assinante</span></th>
                            <th><span className="overline-title">CPF</span></th>
                            <th><span className="overline-title">Produto</span></th>
                            <th className="text-center"><span className="overline-title">Situação</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        {isLoading && (
                            <tr>
                                <td colSpan="5" className="text-center"><Spinner/></td>
                            </tr>
                        )}
                        {data.length === 0 && (
                            <tr>
                                <td colSpan="5"> Nenhum registro encontrado</td>
                            </tr>
                        )}
                        {!isLoading && data.map((row, key) => (
                            <tr key={key}>
                                <td>
                                    {row.Id}
                                </td>
                                <td>
                                    <Link to={`data/${row.Uuid}`} className="border-0">
                                        {row.FullName}
                                    </Link>
                                </td>
                                <td>
                                    {row.TaxNumber}
                                </td>
                                <td>
                                    {row.ProductAlias}
                                </td>
                                <td className="text-center">
                                    {row.ContractStatus !== undefined && row.ContractStatus !== null ? (
                                        <span
                                            className={`badge badge-dot bg-${contractStatus[row.ContractStatus].color}`}>
                                            {contractStatus[row.ContractStatus].label}</span>
                                    ) : (
                                        <span></span>
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <Row>
                        <div className={'col-12 col-offset-0 col-md-9 offset-md-1 col-xl-8 offset-xl-2'}>
                            <Pagination listClassName={'justify-content-center'} itemPerPage={rowPerPage} totalItems={totalRecord} currentPage={currentPage + 1} paginate={handlePagination} />
                        </div>
                    </Row>
                </div>
            </Content>
        </React.Fragment>
    )
}

export default PersonsList;
