import React from "react";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import {
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    BlockBetween,
} from "../components/Component";
import {useAuth} from "../hooks/useAuth";
const Dashboard = () => {
    const {user} = useAuth();
    const {FullName} = {...user};
    return (
        <React.Fragment>
            <Head title="Início"/>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page tag="h3">
                                Olá, {FullName && FullName.split(' ')[0]}
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>Bem vindo(a) ao portal de vendas TokSaúde</p>
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
            </Content>
        </React.Fragment>
    );
};
export default Dashboard;
