import React, {useState, useEffect, useCallback} from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
    Block,
    BlockBetween,
    BlockContent,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle
} from "../../components/block/Block";
import {Button, Icon} from "../../components/Component";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {toastOptions} from "../../utils/Presets";
import {Badge, Spinner} from "reactstrap";
import {useAuth} from "../../hooks/useAuth";
import {parseToDateBR} from "../../utils/Commons";
import useMediaQuery from "../../hooks/useMediaQuery";
import httpClient from "../../utils/HttpClient";
import figEmpty from '../../images/fig/fig-mailbox.png';

const NotificationList = () => {
    const {user} = useAuth();
    const {OwnerId} = {...user};
    const breakpoint = useMediaQuery();
    const [toggleMenu, setToggleMenu] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState(false);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const resp = await httpClient.get('extras/notificacoes', {
                params: {
                    personId: OwnerId,
                    media: 'inbox'
                }
            });
            const {data} = resp.data;
            setData(data);
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
        } finally {
            setLoading(false);
        }
    }, [OwnerId]);

    useEffect(() => {
        fetchData().then();
    }, [fetchData])

    const sortedData = Array.isArray(data) ? data.slice().sort((a, b) => new Date(b.SentAt) - new Date(a.SentAt)) : [];

    return (
        <React.Fragment>
            <Head title="Cadastro de Assinantes"/>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle>Central de notificações</BlockTitle>
                            <BlockDes className="text-soft">
                                Notificações direcionadas a você.
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <Button
                                    className={`btn-icon btn-trigger toggle-expand me-n1 ${breakpoint === 'xs' ? 'active' : ''}`}
                                    onClick={() => setToggleMenu(!toggleMenu)}>
                                    <Icon name="menu-alt-r"></Icon>
                                </Button>
                                <div className="toggle-expand-content"
                                     style={{display: toggleMenu ? "block" : "none"}}>
                                    <ul className="nk-block-tools">
                                        <li className="nk-block-tools-opt">
                                            <Button type={'button'}
                                                    id={'btnAdd'}
                                                    className={'btn-icon'}>
                                                <Icon name="recycle"></Icon>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block>
                    {
                        isLoading
                            ? <BlockContent className={'text-center'}>
                                <Spinner/>
                            </BlockContent>
                            : <div className={'card card-full card-bordered'}>
                                <ul className={'nk-activity'}>
                                    {
                                        sortedData.length > 0
                                            ? sortedData.map((item) => (
                                                <li key={item.Id} className={'nk-activity-item'}>
                                                    <Link
                                                        to={`details/${item.UUID ? item.UUID : item.Id}`}
                                                        className={'d-flex flex-grow-1 flex-row flex-wrap g-2 align-center'}>
                                                        <Badge className="badge-dot"
                                                               color={item.Read ? "light" : "success"}>&nbsp;</Badge>
                                                        <div className={'flex-grow-1'}>
                                                            <span
                                                                className={`title ${!item.Read ? 'fw-bold' : 'text-muted'}`}>{item.Title}</span>
                                                        </div>
                                                        <div
                                                            className={'small'}>{parseToDateBR(item.SentAt, false, true, true)}
                                                        </div>
                                                    </Link>
                                                </li>
                                            ))
                                            : <li className={'nk-activity-item'}>
                                                <div className={'d-flex flex-grow-1 align-center flex-column'}>
                                                    <img src={figEmpty} alt={'img-vazio'} width={'80px'}/>
                                                    <p>Você não possui notificações</p>
                                                </div>
                                            </li>
                                    }
                                </ul>
                            </div>
                    }
                </Block>
            </Content>
        </React.Fragment>
    )
}

export default NotificationList;
