import React, {useState} from "react";
import {
    Col,
    Row,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Card,
    Spinner
} from "reactstrap";
import {
    Button,
    Block,
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    UserAvatar
} from "../../../components/Component";
import {findUpper} from "../../../utils/Utils";
import {usePersonContext} from "../../../context/PersonContext";
import {useNavigate} from "react-router-dom";
import {parseToDateBR} from "../../../utils/Commons";
import classnames from "classnames";
import PersonsForm from "./Form";
import DependentsModal from "./DependentsModal";


const Details = () => {
    const navigate = useNavigate();
    const {personData, dependents, isLoading, fetchDependents} = usePersonContext();
    const [activeTab, setActivetab] = useState("1");
    const [showModalDep, setShowModalDep] = useState(false);
    const [selectedDep, setSelectedDep] = useState({});
    if (!personData || isLoading) {
        return <Block><Spinner color="primary"/></Block>;
    }
    const _handleDependentForm = (row) => {
        setShowModalDep(true);
        setSelectedDep(row);
    }

    const DEFAULT = {
        depId: null,
        holderId: personData.id,
        fullName: '',
        degreeOfRelatedness: '',
        taxNumber: '',
        birthDate: '',
        nationality: '',
        birthCity: '',
        birthState: '',
        gender: '',
        motherFullName: '',
        fatherFullName: '',
        phone: '',
        email: '',
        address: ''
    };

    return (
        <Block>
            {
                (personData.contractStatus !== 1 && personData.contractStatus !== 3)
                    ? (<PersonsForm/>)
                    : (<React.Fragment>
                        <BlockHead size="sm">
                            <BlockBetween>
                                <BlockHeadContent>
                                    <BlockTitle tag="h3" page>
                                        Assinante / <strong className="text-primary small">{personData?.fullName}</strong>
                                    </BlockTitle>
                                </BlockHeadContent>
                                <BlockHeadContent>
                                    <Button
                                        color="light"
                                        outline
                                        className="bg-white d-none d-sm-inline-flex"
                                        onClick={() => navigate('/persons')}
                                    >
                                        <Icon name="arrow-left"></Icon>
                                        <span>Voltar</span>
                                    </Button>
                                </BlockHeadContent>
                            </BlockBetween>
                        </BlockHead>
                        <Block>
                            <Card className="card-bordered">
                                <div className="card-aside-wrap" id="user-detail-block">
                                    <div className="card-content">
                                        <Nav tabs className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                                            <NavItem className="nav-item">
                                                <NavLink
                                                    tag='a'
                                                    href="#tab"
                                                    className={classnames({active: activeTab === '1'})}
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        setActivetab('1');
                                                    }}
                                                >
                                                    <Icon name="user-circle"></Icon>
                                                    <span>Dados Cadastrais</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="nav-item">
                                                <NavLink
                                                    tag='a'
                                                    href="#tab"
                                                    className={classnames({active: activeTab === '2'})}
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        setActivetab('2');
                                                    }}
                                                >
                                                    <Icon name="location"/>
                                                    <span>Endereço</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="nav-item">
                                                <NavLink
                                                    tag='a'
                                                    href="#tab"
                                                    className={classnames({active: activeTab === '3'})}
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        setActivetab('3');
                                                    }}
                                                >
                                                    <Icon name="clip"/>
                                                    <span>Documentos</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="nav-item">
                                                <NavLink
                                                    tag='a'
                                                    href="#tab"
                                                    className={classnames({active: activeTab === '4'})}
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        setActivetab('4');
                                                    }}
                                                >
                                                    <Icon name="users"/>
                                                    <span>Dependentes</span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                </div>
                                <div className="card-inner">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId='1'>
                                            <Block>
                                                <BlockHead className="nk-block-head-line">
                                                    <BlockTitle tag="h6" className="overline-title text-base">
                                                        Dados Cadastrais de assinante
                                                    </BlockTitle>
                                                </BlockHead>
                                                <div className="profile-ud-list">
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Nome Completo</span>
                                                            <span className="profile-ud-value">{personData.fullName}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Data de nascimento</span>
                                                            <span
                                                                className="profile-ud-value">{parseToDateBR(personData.birthDate)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">CPF</span>
                                                            <span className="profile-ud-value">{personData.taxNumber}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">RG</span>
                                                            <span
                                                                className="profile-ud-value">{personData.identity || "-"}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">RG Emissor</span>
                                                            <span className="profile-ud-value">
                                                                 {personData.identityIssuer || "-"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">RG UF</span>
                                                            <span className="profile-ud-value">
                                                                 {personData.identityState || "-"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">RG Data de Emissão</span>
                                                            <span className="profile-ud-value">
                                                                 {personData.identityDate || "-"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Nacionalidade</span>
                                                            <span
                                                                className="profile-ud-value">{personData.nationality}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">UF Natal</span>
                                                            <span
                                                                className="profile-ud-value">{personData.birthState || "-"}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Gênero</span>
                                                            <span className="profile-ud-value">{personData.genre}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Nome do pai</span>
                                                            <span
                                                                className="profile-ud-value">{personData.fatherFullName || "-"}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Nome da Mãe</span>
                                                            <span
                                                                className="profile-ud-value">{personData.motherFullName}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Empresa</span>
                                                            <span className="profile-ud-value">
                                                                 {personData.companyId ? personData.companyId : personData.employerCompany}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Escolaridade</span>
                                                            <span className="profile-ud-value">
                                                                 {personData.education || "-"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Ocupação</span>
                                                            <span className="profile-ud-value">
                                                                 {personData.occupation || "-"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Profissão</span>
                                                            <span className="profile-ud-value">
                                                                 {personData.profession || "-"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Block>
                                            <div className="nk-divider divider md"></div>
                                            <Block>
                                                <BlockHead className="nk-block-head-line">
                                                    <BlockTitle tag="h6" className="overline-title text-base">
                                                        Informações sobre o produto contratado, vencimento e vigência
                                                    </BlockTitle>
                                                </BlockHead>
                                                <div className="profile-ud-list">
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Produto Contratado</span>
                                                            <span
                                                                className="profile-ud-value">{personData.product || "-"}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Data de assinatura</span>
                                                            <span
                                                                className="profile-ud-value">{parseToDateBR(personData.contractDate) || "-"}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Data de vigência</span>
                                                            <span
                                                                className="profile-ud-value">{parseToDateBR(personData.effectiveDate) || "-"}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Dia do vencimento</span>
                                                            <span
                                                                className="profile-ud-value">{personData.dueDate || "-"}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Block>
                                        </TabPane>
                                        <TabPane tabId='2'>
                                            <Block>
                                                <BlockHead className="nk-block-head-line">
                                                    <BlockTitle tag="h6" className="overline-title text-base">
                                                        Informações de endereço e contatos de assinante
                                                    </BlockTitle>
                                                </BlockHead>
                                                <div className="profile-ud-list">
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">CEP</span>
                                                            <span
                                                                className="profile-ud-value">{personData.addressZipcode}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Logradouro</span>
                                                            <span
                                                                className="profile-ud-value">{personData.addressStreet}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Número</span>
                                                            <span
                                                                className="profile-ud-value">{personData.addressNumber}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Complemento</span>
                                                            <span
                                                                className="profile-ud-value">{personData.addressComplement || "-"}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Bairro</span>
                                                            <span
                                                                className="profile-ud-value">{personData.addressNeighborhood}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Cidade</span>
                                                            <span
                                                                className="profile-ud-value">{personData.addressCity}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">UF</span>
                                                            <span
                                                                className="profile-ud-value">{personData.addressState}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Telefone</span>
                                                            <span
                                                                className="profile-ud-value">{personData.contactPhone}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Telefone Alternativo</span>
                                                            <span
                                                                className="profile-ud-value">{personData.contactPhoneAlt || "-"}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud wider">
                                                            <span className="profile-ud-label">Email</span>
                                                            <span
                                                                className="profile-ud-value">{personData.contactEmail}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Block>
                                        </TabPane>
                                        <TabPane tabId='3'>
                                            <Block>
                                                <BlockHead className="nk-block-head-line">
                                                    <BlockTitle tag="h6" className="overline-title text-base">
                                                        Arquivos anexados das documentações exigidas para contratação.
                                                    </BlockTitle>
                                                </BlockHead>
                                                <Block>
                                                    <Row spacing={2}>
                                                        {personData.documents && personData.documents.map((file, index) => (
                                                            <Col key={index} xs={4} lg={2}>
                                                                <Card className="card-bordered gallery h-100">
                                                                    <div
                                                                        className='d-flex flex-column justify-content-center align-items-center'>
                                                                        <img src={file.Src} alt="preview"
                                                                             width='120px'
                                                                             height='120px'/>
                                                                        <span>{file.Description}</span>
                                                                    </div>
                                                                </Card>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </Block>
                                            </Block>
                                        </TabPane>
                                        <TabPane tabId='4'>
                                            <Block>
                                                <Button type={'button'}
                                                        color={'primary'}
                                                        onClick={() => _handleDependentForm(DEFAULT)}>
                                                    <Icon name={'plus'}/>
                                                    <span>INCLUIR DEPENDENTE</span>
                                                </Button>
                                            </Block>
                                            <Block>
                                                <BlockHead className="nk-block-head-line">
                                                    <BlockTitle tag="h6" className="overline-title text-base">
                                                        Dependentes Incluídos
                                                        ({dependents.length || 0})
                                                    </BlockTitle>
                                                </BlockHead>
                                                <ul className="nk-support">
                                                    {dependents && dependents.map((item, key) => (
                                                        <li className="nk-support-item p-1"
                                                            key={key}>
                                                            <UserAvatar image={item.image}
                                                                        text={item.depFullName && findUpper(item.depFullName)}/>
                                                            <div className="nk-support-content">
                                                                <div className="title">
                                                                    <span>{item.depFullName}</span>
                                                                </div>
                                                                <p>{item.depDegreeOfRelatedness}</p>
                                                                <span className="time">CPF: {item.depTaxNumber}</span>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <DependentsModal isOpen={showModalDep}
                                                                 holderId={personData.id}
                                                                 dependentId={selectedDep?.depId}
                                                                 toggle={() => setShowModalDep(!showModalDep)}
                                                                 onSave={() => {
                                                                     setShowModalDep(!showModalDep);
                                                                     fetchDependents();
                                                                 }}/>
                                            </Block>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </Card>
                        </Block>
                    </React.Fragment>)
            }
        </Block>
    );
};
export default Details;
