import {useParams} from "react-router-dom";
import {PersonProvider} from "../../context/PersonContext";
import React from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import Details from "./components/Details";

const PersonFormContainer = () => {
    const {personId} = useParams();

    return (
        <React.Fragment>
            <Head title='Dados do Assinante'/>
            <Content>
                <PersonProvider personId={personId}>
                    <Details/>
                </PersonProvider>
            </Content>
        </React.Fragment>
    )
}


export default PersonFormContainer;