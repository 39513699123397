import React, {useCallback, useEffect, useState} from "react";
import {
    Nav,
    NavItem,
    NavLink,
    Spinner,
    TabContent,
    TabPane
} from "reactstrap";
import {Col, Row} from "../../../components/grid/Grid";
import {CNPJ, CPF, ZIPCODE, PHONE} from "../../../utils/Masks";
import {useFormik} from "formik";
import {
    formatPhone,
    formatZipcode,
    onlyNumber,
    parseToISODate
} from "../../../utils/Commons";
import {
    companyType,
    dueDates,
    genres,
    states
} from "../../../utils/Types";
import {
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Button,
    Icon
} from "../../../components/Component";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {toastOptions} from "../../../utils/Presets";
import {useAuth} from "../../../hooks/useAuth";
import Dropzone from "react-dropzone";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import OutlinedInput from "../../../components/input/outlined-input/OutlinedInput";
import OutlinedSelect from "../../../components/select/OutlinedSelect";
import * as Yup from "yup";
import httpClient from "../../../utils/HttpClient";
import useMediaQuery from "../../../hooks/useMediaQuery";
import classnames from "classnames";
import Swal from "sweetalert2";

const dropzoneDefaults = {
    'multiple': false,
    'maxSize': 5242880,
    'accept': ["image/*", "application/pdf", "text/plain"],
}

const CompaniesForm = () => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const [projects, setProjects] = useState([]);
    const [entities, setEntities] = useState([]);
    const [companyTypes, setCompanyTypes] = useState([]);
    const [statesList, setStatesList] = useState([]);
    const [genresList, setGenresList] = useState([]);
    const [isLoadingCompany, setLoadingCompany] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [isLoadingCNPJ, setLoadingCNPJ] = useState(false);
    const [isLoadingCPF, setLoadingCPF] = useState(false);
    const [isLoadingProjects, setLoadingProjects] = useState(false);
    const [isLoadingEntities, setLoadingEntities] = useState(false);
    const [isLoadingAddress, setLoadingAddress] = useState(false);
    const [activeTab, setActivetab] = useState("1");
    const [holderId, setHolderId] = useState(null);
    const {user} = useAuth();
    const {Id, BrokerId} = {...user.Profile};
    const {companyId} = useParams();
    const breakpoint = useMediaQuery();
    const sellerId = Id;
    const brokerId = BrokerId;
    const navigate = useNavigate();

    const {
        values,
        isValid,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setValues,
    } = useFormik({
        initialValues: {
            taxNumber: '',
            fullName: '',
            type: '',
            businessName: '',
            foundationDate: '',
            entityId: '',
            holderType: '',
            holderName: '',
            holderTaxNumber: '',
            holderIdentity: '',
            holderGender: '',
            holderNationality: '',
            holderBirthDate: '',
            holderBirthCity: '',
            holderBirthState: '',
            holderMotherFullName: '',
            holderFatherFullName: '',
            holderPhone: '',
            holderEmail: '',
            projectId: '',
            dateContract: "",
            effectiveDate: '',
            dueDate: '',
            addressZipcode: '',
            addressStreet: '',
            addressNumber: '',
            addressComplement: '',
            addressNeighborhood: '',
            addressCity: '',
            addressState: '',
            contactPhone: '',
            contactPhoneAlt: '',
            contactEmail: '',
            sendAnalisys: false,
            fileCNPJ: [],
            fileSocialContract: [],
            fileProofResidence: [],
            fileGFIP: [],
            fileIdentity: [],
            fileDriveLicense: [],
            sendCard: false
        },
        validationSchema: Yup.object({
            taxNumber: Yup.string().required('Preencha este campo'),
            fullName: Yup.string().required('Preencha este campo'),
            type: Yup.string().required('Preencha este campo'),
            businessName: Yup.string().required('Preencha este campo'),
            foundationDate: Yup.string().required('Preencha este campo'),
            holderTaxNumber: Yup.string().required('Preencha este campo'),
            holderName: Yup.string().required('Preencha este campo'),
            holderIdentity: Yup.string().required('Preencha este campo'),
            holderBirthDate: Yup.string().required('Preencha este campo'),
            holderGender: Yup.string().required('Preencha este campo'),
            holderMotherFullName: Yup.string().required('Preencha este campo'),
            projectId: Yup.number().required('Preencha este campo'),
            dateContract: Yup.date().required('Preencha este campo'),
            effectiveDate: Yup.date().required('Preencha este campo'),
            dueDate: Yup.number().required('Preencha este campo'),
            addressZipcode: Yup.string().required('Preencha este campo'),
            addressStreet: Yup.string().required('Preencha este campo'),
            addressNeighborhood: Yup.string().required('Preencha este campo'),
            addressCity: Yup.string().required('Preencha este campo'),
            addressState: Yup.string().required('Preencha este campo'),
            contactPhone: Yup.string().required('Preencha este campo'),
            contactEmail: Yup.string().email('E-mail inválido').required('Preencha este campo')
        }),
        onSubmit: async (v) => {
            try {
                setSaving(true);
                const confirm = await Swal.fire({
                    title: "Salvar alterações",
                    text: "Deseja salvar as alterações realizadas no cadastro?",
                    showCancelButton: true,
                    confirmButtonText: "Sim",
                    cancelButtonText: "Não",
                });
                if (!confirm.isConfirmed) return;
                const url = companyId ? `credenciamento/empresa/${companyId}` : 'credenciamento/empresa';
                const method = companyId ? 'put' : 'post';
                const d = {
                    sellerId,
                    brokerId,
                    fullName: v.fullName,
                    taxNumber: v.taxNumber,
                    type: v.type,
                    businessName: v.businessName,
                    foundationDate: v.foundationDate,
                    entityId: v.entityId,
                    projectId: v.projectId,
                    dateContract: v.dateContract,
                    effectiveDate: v.effectiveDate,
                    dueDate: v.dueDate,
                    address: {
                        zipCode: v.addressZipcode,
                        street: v.addressStreet,
                        number: v.addressNumber,
                        complement: v.addressComplement,
                        neighborhood: v.addressNeighborhood,
                        city: v.addressCity,
                        state: v.addressState,
                        phone: v.contactPhone,
                        phoneAlt: v.contactPhoneAlt,
                        email: v.contactEmail,
                    },
                    holders: [
                        {
                            Id: holderId,
                            type: 0,
                            fullName: v.holderName,
                            taxNumber: v.holderTaxNumber,
                            identity: v.holderIdentity,
                            gender: v.holderGender,
                            motherFullName: v.holderMotherFullName,
                            fatherFullName: v.holderFatherFullName,
                            nationality: v.holderNationality,
                            birthDate: v.holderBirthDate,
                            birthState: v.holderBirthState,
                            birthCity: v.holderBirthCity,
                            address: {
                                zipCode: v.addressZipcode,
                                street: v.addressStreet,
                                number: v.addressNumber,
                                complement: v.addressComplement,
                                neighborhood: v.addressNeighborhood,
                                city: v.addressCity,
                                state: v.addressState,
                                phone: v.contactPhone,
                                phoneAlt: v.contactPhoneAlt,
                                email: v.contactEmail
                            },
                        }
                    ],
                    contractStatus: v.sendAnalisys ? 3 : 0,
                    sendCard: v.sendCard,
                    documents: [],
                };
                if (v.fileSocialContract[0]?.base64) {
                    d.documents.push({
                        type: 5,
                        base64: v.fileSocialContract[0].base64,
                        description: 'Contrato Social',
                    });
                }
                if (v.fileGFIP[0]?.base64) {
                    d.documents.push({
                        type: 1006,
                        base64: v.fileGFIP[0].base64,
                        description: 'GFIP',
                    });
                }
                if (v.fileDriveLicense[0]?.base64) {
                    d.documents.push({
                        type: 3,
                        base64: v.fileDriveLicense[0]?.base64,
                        description: 'CNH do representante legal'
                    })
                    d.holders[0].push = [{
                        type: 3,
                        base64: v.fileDriveLicense[0]?.base64,
                        description: 'CNH do representante legal'
                    }];
                }
                await httpClient.request({
                    url,
                    method,
                    data: d
                });
                toast.success('Registro salvo com sucesso', {
                    ...toastOptions
                });
                navigate('/companies');
            } catch (e) {
                const {errors} = {...e.response.data};
                if (typeof errors === 'object') {
                    for (const key in errors) {
                        toast.error(errors[key], {
                            ...toastOptions
                        });
                    }
                } else {
                    toast.error(e.message, {
                        ...toastOptions
                    });
                }
            } finally {
                setSaving(false);
            }
        }
    });

    const handleDropChange = (acceptedFiles, fieldName) => {
        setFieldValue(fieldName,
            acceptedFiles.map((file) => {
                const {type} = file;
                const mime = type.split('/');
                const reader = new FileReader();
                reader.addEventListener('load', e => {
                    file.base64 = e.target.result;
                });
                reader.readAsDataURL(file);
                return Object.assign(file, {
                    preview: mime[0] === 'image' ? URL.createObjectURL(file) : 'https://curate.nd.edu/downloads/und:9880vq30s7m/thumbnail',
                })
            })
        );
    };

    const _queryCNPJ = async () => {
        try {
            if (!values.taxNumber) return;
            setLoadingCNPJ(true)
            const res = await httpClient.get(`extra/consultar/cnpj/${onlyNumber(values.taxNumber)}`);
            const {
                nome,
                fantasia,
                abertura,
                cep,
                logradouro,
                numero,
                complemento,
                bairro,
                municipio,
                uf,
                telefone,
                email,
            } = {...res.data.data};
            await setFieldValue('fullName', nome, false);
            await setFieldValue('businessName', fantasia, false);
            await setFieldValue('foundationDate', parseToISODate(abertura), false);
            await setFieldValue('addressZipcode', formatZipcode(cep), false);
            await setFieldValue('addressStreet', logradouro, false);
            await setFieldValue('addressNumber', numero, true);
            await setFieldValue('addressComplement', complemento, false);
            await setFieldValue('addressNeighborhood', bairro, false);
            await setFieldValue('addressCity', municipio, false);
            await setFieldValue('addressState', uf, false);
            await setFieldValue('contactPhone', formatPhone(telefone), false);
            await setFieldValue('contactEmail', email, false);
        } catch (e) {
            const {errors} = {...e.response.data};
            if (typeof errors === 'object') {
                for (const key in errors) {
                    toast.error(errors[key], {
                        ...toastOptions
                    });
                }
                if(e.response.status.toString().includes("40")) {
                    toast.error("Confira se o CNPJ está correto", {...toastOptions});
                }
            }
        } finally {
            setLoadingCNPJ(false);
        }
    }

    const _queryCPF = async () => {
        try {
            if (!values.holderTaxNumber) return;
            setLoadingCPF(true)
            const res = await httpClient.get(`extra/consultar/cpf/${onlyNumber(values.holderTaxNumber)}/?data=01/01/2001`);
            const {
                nome_da_pf,
                data_nascimento,
            } = {...res.data.data};
            await setFieldValue('holderName', nome_da_pf, false);
            await setFieldValue('holderBirthDate', parseToISODate(data_nascimento), false);
        } catch (e) {
            const {errors} = {...e.response.data};
            if (typeof errors === 'object') {
                for (const key in errors) {
                    toast.error(errors[key], {
                        ...toastOptions
                    });
                }
                if(e.response.status.toString().includes("40")) {
                    toast.error("Confira se a Data de Nascimento e o número de CPF estão corretos", {...toastOptions});
                }
            }
        } finally {
            setLoadingCPF(false);
        }
    }

    const _queryZipcode = async () => {
        try {
            if (!values.addressZipcode) return;
            setLoadingAddress(true)
            const res = await httpClient.get(`extra/consultar/cep/${onlyNumber(values.addressZipcode)}`);
            const {
                logradouro,
                complemento,
                bairro,
                localidade,
                uf,
            } = {...res.data.data};
            await setFieldValue('addressStreet', logradouro, false);
            await setFieldValue('addressComplement', complemento, false);
            await setFieldValue('addressNeighborhood', bairro, false);
            await setFieldValue('addressCity', localidade, false);
            await setFieldValue('addressState', uf, false);
        } catch (e) {
            const {errors} = {...e.response.data};
            if (typeof errors === 'object') {
                for (const key in errors) {
                    toast.error(errors[key], {
                        ...toastOptions
                    });
                }
                if(e.response.status.toString().includes("40")) {
                    toast.error("Confira se o CEP está correto", {...toastOptions});
                }
            }
        } finally {
            setLoadingAddress(false);
        }
    }

    const fetchCompanyTypes = () => {
        const options = [];
        for (const key in companyType) {
            options.push({label: companyType[key], value: key});
        }
        setCompanyTypes(options);
    }

    const fetchStates = () => {
        const options = [];
        for (const key in states) {
            options.push({label: states[key], value: states[key]});
        }
        setStatesList(options);
    }
    const fetchGender = () => {
        const options = [];
        for (const key in genres) {
            options.push({label: genres[key], value: genres[key][0]});
        }
        setGenresList(options);
    }

    const fetchProject = useCallback(async () => {
        try {
            setLoadingProjects(true)
            const res = await httpClient.get('/comercial/projetos', {
                params: {
                    brokerId: user.Profile.BrokerId
                }
            });
            const {data} = res.data;
            const prj = [];
            for (const key in data) {
                const {Id, Description} = data[key];
                prj.push({label: Description, value: Id});
            }
            setProjects(prj);
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
        } finally {
            setLoadingProjects(false);
        }
    }, [user.Profile.BrokerId]);

    const fetchEntity = useCallback(async () => {
        try {
            setLoadingEntities(true);
            const res = await httpClient.get('/credenciamento/entidades');
            const {data} = res.data;
            const ent = [];
            for (const key in data) {
                const {Id, BusinessName} = data[key];
                ent.push({label: BusinessName, value: Id});
            }
            setEntities(ent);
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
        } finally {
            setLoadingEntities(false);
        }
    }, []);

    const fetchCompany = useCallback(async () => {
        try {
            if (!companyId) return;
            setLoadingCompany(true);
            const res = await httpClient.get(`/credenciamento/empresa/${companyId}`);
            const {data} = res.data;
            const {
                Id,
                TaxNumber,
                Name,
                Type,
                BusinessName,
                FoundationDate,
                EntityId,
                ProjectId,
                DateContract,
                EffectiveDate,
                DueDate,
                Address,
                Holders,
                Documents,
                ContractStatus,
                SendCard
            } = {...data};
            const {
                Zipcode,
                Street,
                Number,
                Complement,
                Region,
                City,
                State,
                Phone,
                PhoneAlt,
                Email
            } = {...Address};
            const Holder = Holders && Holders.length > 0 ? { ...Holders[0] } : {};

            const FileSocialContract = [];
            const FileGFIP = [];
            const FileDriveLicense = [];
            for (const index in Documents) {
                const {Src, Type, TypeDescription} = {...Documents[index]};
                switch (Type) {
                    case 3:
                        FileDriveLicense.push({preview: Src, name: TypeDescription});
                        break;
                    case 5:
                        FileSocialContract.push({preview: Src, name: TypeDescription});
                        break;
                    case 1006:
                        FileGFIP.push({preview: Src, name: TypeDescription});
                        break;
                    default:
                }
            }
            if (Holders) {
                for (const index in Holder.Documents) {
                    const {Src, Type, TypeDescription} = {...Holder.Documents[index]};
                    switch (Type) {
                        case 3:
                            FileDriveLicense.push({preview: Src, name: TypeDescription});
                            break;
                        default:
                    }
                }
            }
            await setValues({
                'id': Id,
                'sendAnalisys': ContractStatus > 0,
                'taxNumber': TaxNumber,
                'fullName': Name,
                'type': Type,
                'businessName': BusinessName,
                'foundationDate': FoundationDate,
                'entityId': EntityId,
                'holderType': Holder?.Type,
                'holderName': Holder?.FullName,
                'holderTaxNumber': Holder?.TaxNumber,
                'holderIdentity': Holder?.Identity,
                'holderGender': Holder?.Gender,
                'holderNationality': Holder?.Nationality,
                'holderBirthDate': Holder?.BirthDate,
                'holderBirthCity': Holder?.BirthCity,
                'holderBirthState': Holder?.BirthState,
                'holderMotherFullName': Holder?.MotherFullName,
                'holderFatherFullName': Holder?.FatherFullName,
                'projectId': ProjectId,
                'dateContract': DateContract,
                'effectiveDate': EffectiveDate,
                'dueDate': DueDate,
                'addressZipcode': Zipcode,
                'addressStreet': Street,
                'addressNumber': Number,
                'addressComplement': Complement,
                'addressNeighborhood': Region,
                'addressCity': City,
                'addressState': State,
                'contactPhone': Phone,
                'contactPhoneAlt': PhoneAlt,
                'contactEmail': Email,
                'sendCard': SendCard,
                'fileSocialContract': FileSocialContract,
                'fileGFIP': FileGFIP,
                'fileDriveLicense': FileDriveLicense
            });
            if (data.Holders) setHolderId(data.Holders[0].Id);
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
        } finally {
            setLoadingCompany(false);
        }
    }, [companyId, setValues]);

    useEffect(() => {
        fetchCompany().then();
        fetchProject().then();
        fetchEntity().then();
        fetchCompanyTypes();
        fetchStates();
        fetchGender();
    }, [fetchCompany, fetchEntity, fetchProject, setValues])

    const _selectedValue = (list, value) => {
        return list.filter((opt) => {
            return opt?.value.toString() === value?.toString()
        })
    };

    const _handleSendAnalisys = async (e) => {
        let {checked} = {...e.target};
        if (checked) {
            await Swal.fire({
                title: "ATENÇÃO!!!",
                text: "Ao marcar esta opção, o dados serão enviados para análise de validação e só voltará a poder ser editado após pendenciamento por parte do setor de cadastro!",
                confirmButtonText: "OK, estou ciente!",
            });
        }
        await setFieldValue('sendAnalisys', checked)
    }

    return (
        <React.Fragment>
            <Head title="Cadastro de Empresas"/>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Cadastro de Empresas</BlockTitle>
                            <BlockDes className={'text-soft'}>Dados cadastrais da empresa cliente.</BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className={'toggle-wrap nk-block-tools-toggle'}>
                                <Button
                                    className={`btn-icon btn-trigger toggle-expand me-n1 ${breakpoint === 'xs' ? 'active' : ''}`}
                                    onClick={() => setToggleMenu(!toggleMenu)}>
                                    <Icon name="menu-alt-r"></Icon>
                                </Button>
                                <div className="toggle-expand-content"
                                     style={{display: toggleMenu ? "block" : "none"}}>
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox"
                                                       className="custom-control-input"
                                                       checked={values.sendAnalisys > 0}
                                                       onChange={_handleSendAnalisys}
                                                       id="sendAnalisys"/>
                                                <label className="custom-control-label" htmlFor="sendAnalisys">
                                                    Enviar para análise
                                                </label>
                                            </div>
                                        </li>
                                        <li className="nk-block-tools-opt">
                                            <Button
                                                type={'submit'}
                                                form={'form'}
                                                color={'primary'}
                                                className={'btn-dim'}
                                                disabled={!isValid || isSaving || isLoadingCompany}>
                                                {isSaving ? <Spinner size={'sm'}/> : <Icon name={'check'}/>}
                                                <span>SALVAR</span>
                                            </Button>
                                        </li>
                                        <li className={'nk-block-tool-opt'}>
                                            <Button
                                                type={'button'}
                                                color="light"
                                                outline
                                                onClick={() => navigate(-1)}
                                            >
                                                <Icon name="arrow-left"></Icon>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <form id={'form'} onSubmit={handleSubmit}>
                    <Row className={'g-3 my-4'}>
                        <Col lg={3} md={4}>
                            <OutlinedInput
                                id={'taxNumber'}
                                name={'taxNumber'}
                                required
                                mask={CNPJ}
                                label={'CNPJ'}
                                size={'xl'}
                                value={values.taxNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={errors.taxNumber && touched.taxNumber}
                                error={errors.taxNumber}
                                iconRight={
                                    <Button
                                        type={'button'}
                                        className={'btn-icon me-2'}
                                        onClick={_queryCNPJ}>
                                        {isLoadingCNPJ ? <Spinner size={'sm'}/> :
                                            <Icon name={'search'}/>}
                                    </Button>
                                }
                            />
                        </Col>
                        <Col lg={6} md={8}>
                            <OutlinedInput
                                id={'fullName'}
                                name={'fullName'}
                                required
                                label={'Razão social'}
                                size={'xl'}
                                value={values.fullName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={errors.fullName && touched.fullName}
                                error={errors.fullName}/>
                        </Col>
                        <Col lg={3} md={4}>
                            <OutlinedSelect
                                id={'type'}
                                name={'type'}
                                required
                                label={'Natureza jurídica'}
                                options={companyTypes}
                                size={'xl'}
                                placeholder={'Selecione'}
                                value={_selectedValue(companyTypes, values?.type)}
                                onChange={(opt) => setFieldValue('type', opt?.value)}
                                onBlur={handleBlur}
                                isInvalid={errors.type && touched.type}
                                setValue={() => 0}
                                error={errors.type}
                            />
                        </Col>
                        <Col lg={4} md={8}>
                            <OutlinedInput
                                id={'businessName'}
                                name={'businessName'}
                                required
                                label={'Nome fantasia'}
                                size={'xl'}
                                value={values.businessName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={errors.businessName && touched.businessName}
                                error={errors.businessName}/>
                        </Col>
                        <Col lg={3} md={4}>
                            <OutlinedInput
                                id={'foundationDate'}
                                name={'foundationDate'}
                                type={'date'}
                                required
                                label={'Data de fundação'}
                                size={'xl'}
                                value={values.foundationDate}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={errors.foundationDate && touched.foundationDate}
                                error={errors.foundationDate}/>
                        </Col>
                        <Col lg={5} md={8}>
                            <OutlinedSelect
                                id={'entity'}
                                name={'entity'}
                                label={'Entidade associada'}
                                size={'xl'}
                                loading={isLoadingEntities}
                                placeholder={'Selecione'}
                                defaultValue={values.entityId}
                                options={entities}
                                value={_selectedValue(entities, values?.entityId)}
                                onChange={(opt) => setFieldValue('entityId', opt?.value)}
                                onBlur={handleBlur}
                                isInvalid={errors.entityId && touched.entityId}
                                error={errors.entityId}/>
                        </Col>
                    </Row>
                    <h6 className="mb-4">
                        Informações sobre o produto contratado, vencimento e vigência.
                    </h6>
                    <Row className={'g-3 mb-4'}>
                        <Col lg={12}>
                            <OutlinedSelect
                                id={'projectId'}
                                name={'projectId'}
                                label={'Produto contratado'}
                                placeholder={'Selecione'}
                                required
                                loading={isLoadingProjects}
                                options={projects}
                                size={'xl'}
                                value={_selectedValue(projects, values?.projectId)}
                                onChange={(opt) => setFieldValue('projectId', opt?.value)}
                                onBlur={handleBlur}
                                isInvalid={errors.projectId && touched.projectId}
                                error={errors.projectId}/>
                        </Col>
                        <Col lg={4} md={4}>
                            <OutlinedInput
                                id={'dateContract'}
                                name={'dateContract'}
                                required
                                type={'date'}
                                label={'Data de assinatura'}
                                title={"Data de assinatura"}
                                size={'xl'}
                                value={values.dateContract}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={errors.dateContract && touched.dateContract}
                                error={errors.dateContract}/>
                        </Col>
                        <Col lg={4} md={4}>
                            <OutlinedInput
                                id={'effectiveDate'}
                                name={'effectiveDate'}
                                required
                                type={'date'}
                                label={'Data da vigência'}
                                size={'xl'}
                                value={values.effectiveDate}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={errors.effectiveDate && touched.effectiveDate}
                                error={errors.effectiveDate}/>
                        </Col>
                        <Col lg={4} md={4}>
                            <OutlinedSelect
                                id={'dueDate'}
                                name={'dueDate'}
                                label={'Dia do vencimento'}
                                placeholder={'Selecione'}
                                required
                                options={dueDates}
                                size={'xl'}
                                value={_selectedValue(dueDates, values?.dueDate)}
                                onChange={(opt) => setFieldValue('dueDate', opt?.value)}
                                onBlur={handleBlur}
                                isInvalid={errors.dueDate && touched.dueDate}
                                error={errors.dueDate}/>
                        </Col>
                        <Col size={12}>
                            <div className="custom-control custom-switch text-left">
                                <input type="checkbox"
                                       className="custom-control-input"
                                       checked={values.sendCard}
                                       onChange={(e) => setFieldValue('sendCard', e.currentTarget.checked)}
                                       id="sendCard"/>
                                <label className="custom-control-label"
                                       htmlFor="sendCard">
                                    Emitir carteirinha para beneficiários e dependentes (R$5,00)
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Nav tabs className={'mb-2'}>
                        <NavItem>
                            <NavLink
                                tag="a"
                                href="#tab"
                                className={classnames({active: activeTab === "1"})}
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setActivetab("1");
                                }}
                            >
                                <Icon name="location"/> <span>Endereço</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                tag="a"
                                href="#tab"
                                className={classnames({active: activeTab === "2"})}
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setActivetab("2");
                                }}
                            >
                                <Icon name="user"/> <span>Representante</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                tag="a"
                                href="#tab"
                                className={classnames({active: activeTab === "3"})}
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setActivetab("3");
                                }}
                            >
                                <Icon name="clip"/> <span>Documentos</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <p>
                                Informações de endereço e contatos da empresa.
                            </p>
                            <Row className={'g-3'}>
                                <Col lg={3} md={4}>
                                    <OutlinedInput
                                        mask={ZIPCODE}
                                        id={'addressZipcode'}
                                        name={'addressZipcode'}
                                        required
                                        label={'CEP'}
                                        size={'xl'}
                                        value={values.addressZipcode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.addressZipcode && touched.addressZipcode}
                                        error={errors.addressZipcode}
                                        iconRight={
                                            <Button
                                                type={'button'}
                                                className={'btn-icon me-2'}
                                                onClick={_queryZipcode}>
                                                {isLoadingAddress ? <Spinner size={'sm'}/> :
                                                    <Icon name={'search'}/>}
                                            </Button>
                                        }
                                    />
                                </Col>
                                <Col lg={7} md={8}>
                                    <OutlinedInput
                                        id={'addressStreet'}
                                        name={'addressStreet'}
                                        required
                                        label={'Logradouro (Rua, Av., alameda...)'}
                                        size={'xl'}
                                        value={values.addressStreet}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.addressStreet && touched.addressStreet}
                                        error={errors.addressStreet}/>
                                </Col>
                                <Col size={3} lg={2} md={4}>
                                    <OutlinedInput
                                        id={'addressNumber'}
                                        name={'addressNumber'}
                                        label={'Número'}
                                        size={'xl'}
                                        value={values.addressNumber || 0}
                                        onChange={handleChange}
                                        onBlur={handleBlur}/>
                                </Col>
                                <Col size={9} lg={4} md={8}>
                                    <OutlinedInput
                                        id={'addressComplement'}
                                        name={'addressComplement'}
                                        label={'Complemento'}
                                        size={'xl'}
                                        value={values.addressComplement}
                                        onChange={handleChange}
                                        onBlur={handleBlur}/>
                                </Col>
                                <Col lg={3} md={5}>
                                    <OutlinedInput
                                        id={'addressNeighborhood'}
                                        name={'addressNeighborhood'}
                                        required
                                        label={'Bairro'}
                                        size={'xl'}
                                        value={values.addressNeighborhood}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.addressNeighborhood && touched.addressNeighborhood}
                                        error={errors.addressNeighborhood}/>
                                </Col>
                                <Col size={9} lg={3} md={5}>
                                    <OutlinedInput
                                        id={'addressCity'}
                                        name={'addressCity'}
                                        required
                                        label={'Cidade'}
                                        size={'xl'}
                                        value={values.addressCity}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.addressCity && touched.addressCity}
                                        error={errors.addressCity}/>
                                </Col>
                                <Col size={3} md={2}>
                                    <OutlinedSelect
                                        id={'addressState'}
                                        name={'addressState'}
                                        required
                                        label={'UF'}
                                        size={'xl'}
                                        placeholder={'Selecione'}
                                        options={statesList}
                                        value={_selectedValue(statesList, values?.addressState)}
                                        onChange={(opt) => setFieldValue('addressState', opt?.value)}
                                        onBlur={handleBlur}
                                        isInvalid={errors.addressState && touched.addressState}
                                        error={errors.addressState}/>
                                </Col>
                                <Col size={6} lg={3} md={3}>
                                    <OutlinedInput
                                        mask={PHONE}
                                        id={'contactPhone'}
                                        name={'contactPhone'}
                                        required
                                        label={'Telefone principal'}
                                        size={'xl'}
                                        value={values.contactPhone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.contactPhone && touched.contactPhone}
                                        error={errors.contactPhone}/>
                                </Col>
                                <Col size={6} lg={3} md={3}>
                                    <OutlinedInput
                                        mask={PHONE}
                                        id={'contactPhoneAlt'}
                                        name={'contactPhoneAlt'}
                                        label={'Tel. alternativo'}
                                        size={'xl'}
                                        value={values.contactPhoneAlt || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Col>
                                <Col lg={6} md={6}>
                                    <OutlinedInput
                                        id={'contactEmail'}
                                        name={'contactEmail'}
                                        required
                                        type={'email'}
                                        label={'E-mail principal'}
                                        size={'xl'}
                                        value={values.contactEmail}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.contactEmail && touched.contactEmail}
                                        error={errors.contactEmail}/>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <p>
                                Informações sobre o representante legal a empresa.
                            </p>
                            <Row className={'g-3 mb-4'}>
                                <Col lg={3} md={4}>
                                    <OutlinedInput
                                        mask={CPF}
                                        id={'holderTaxNumber'}
                                        name={'holderTaxNumber'}
                                        required
                                        label={'CPF'}
                                        size={'xl'}
                                        value={values.holderTaxNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.holderTaxNumber && touched.holderTaxNumber}
                                        error={errors.holderTaxNumber}
                                        iconRight={
                                            <Button
                                                type={'button'}
                                                className={'btn-icon me-2'}
                                                onClick={_queryCPF}>
                                                {isLoadingCPF ? <Spinner size={'sm'}/> : <Icon name={'search'}/>}
                                            </Button>
                                        }/>
                                </Col>
                                <Col lg={6} md={8}>
                                    <OutlinedInput
                                        id={'holderName'}
                                        name={'holderName'}
                                        required
                                        label={'Nome completo'}
                                        size={'xl'}
                                        value={values.holderName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.holderName && touched.holderName}
                                        error={errors.holderName}/>
                                </Col>
                                <Col lg={3} md={4}>
                                    <OutlinedInput
                                        id={'holderIdentity'}
                                        name={'holderIdentity'}
                                        required
                                        label={'RG'}
                                        size={'xl'}
                                        maxLength={'14'}
                                        value={values.holderIdentity}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.holderIdentity && touched.holderIdentity}
                                        error={errors.holderIdentity}/>
                                </Col>
                                <Col lg={3} md={4}>
                                    <OutlinedInput
                                        id={'holderBirthDate'}
                                        name={'holderBirthDate'}
                                        required
                                        type={'date'}
                                        label={'Data de nascimento'}
                                        size={'xl'}
                                        value={values.holderBirthDate}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.holderBirthDate && touched.holderBirthDate}
                                        error={errors.holderBirthDate}/>
                                </Col>
                                <Col lg={3} md={4}>
                                    <OutlinedInput
                                        id={'holderNationality'}
                                        name={'holderNationality'}
                                        label={'Nacionalidade'}
                                        size={'xl'}
                                        required
                                        value={values.holderNationality}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.holderNationality && touched.holderNationality}
                                        error={errors.holderNationality}/>
                                </Col>
                                <Col lg={4} md={6}>
                                    <OutlinedInput
                                        id={'holderBirthCity'}
                                        name={'holderBirthCity'}
                                        label={'Cidade natal'}
                                        size={'xl'}
                                        required
                                        value={values.holderBirthCity}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.holderBirthCity && touched.holderBirthCity}
                                        error={errors.holderBirthCity}/>
                                </Col>
                                <Col lg={2} md={2}>
                                    <OutlinedSelect
                                        id={'holderBirthState'}
                                        name={'holderBirthState'}
                                        required
                                        label={'UF natal'}
                                        size={'xl'}
                                        placeholder={'Selecione'}
                                        options={statesList}
                                        value={_selectedValue(statesList, values?.holderBirthState)}
                                        onChange={(opt) => setFieldValue('holderBirthState', opt?.value)}
                                        onBlur={handleBlur}
                                        isInvalid={errors.holderBirthState && touched.holderBirthState}
                                        error={errors.holderBirthState}/>
                                </Col>
                                <Col lg={2} md={4}>
                                    <OutlinedSelect
                                        id={'holderGender'}
                                        name={'holderGender'}
                                        required
                                        options={genresList}
                                        label={'Gênero'}
                                        size={'xl'}
                                        placeholder={'Selecione'}
                                        value={_selectedValue(genresList, values?.holderGender)}
                                        onChange={(opt) => setFieldValue('holderGender', opt?.value)}
                                        onBlur={handleBlur}
                                        isInvalid={errors.holderGender && touched.holderGender}
                                        error={errors.holderGender}/>
                                </Col>
                                <Col lg={5} md={6}>
                                    <OutlinedInput
                                        id={'holderMotherFullName'}
                                        name={'holderMotherFullName'}
                                        label={'Nome da mãe'}
                                        size={'xl'}
                                        required
                                        value={values.holderMotherFullName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.holderMotherFullName && touched.holderMotherFullName}
                                        error={errors.holderMotherFullName}/>
                                </Col>
                                <Col lg={5} md={6}>
                                    <OutlinedInput
                                        id={'holderFatherFullName'}
                                        name={'holderFatherFullName'}
                                        label={'Nome da pai'}
                                        size={'xl'}
                                        value={values.holderFatherFullName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}/>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="3">
                            <p>
                                Anexar arquivos das documentações exigidas para contratação.
                            </p>
                            <Row className={'g-3'}>
                                <Col size={4}>
                                    <label htmlFor={'fileSocialContract'} className="form-label">
                                        Contrato Social<span className={'text-danger'}>*</span>
                                    </label>
                                    <Dropzone
                                        {...dropzoneDefaults}
                                        onDrop={
                                            (acceptedFiles) => handleDropChange(acceptedFiles, 'fileSocialContract')
                                        }
                                    >
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div {...getRootProps({className: "dropzone upload-zone dz-clickable"})}>
                                                    <input id={'fileSocialContract'} {...getInputProps()} />
                                                    {values.fileSocialContract.length === 0 && (
                                                        <div className="dz-message">
                                                                    <span className="dz-message-text">
                                                                        Arraste e solte
                                                                    </span>
                                                            <span className="dz-message-or">ou</span>
                                                            <Button type={'button'}
                                                                    color="primary">SELECIONE</Button>
                                                        </div>
                                                    )}
                                                    {values.fileSocialContract.map((file) => (
                                                        <div key={file.name}
                                                             className="dz-preview dz-processing dz-image-preview dz-error dz-complete justify-content-center d-flex">
                                                            <div className="dz-image">
                                                                <img src={file.preview} alt="preview"/>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </Col>
                                <Col size={4}>
                                    <label htmlFor={'fileGFIP'} className="form-label">
                                        Documento GFIP<span className={'text-danger'}>*</span>
                                    </label>
                                    <Dropzone
                                        {...dropzoneDefaults}
                                        onDrop={
                                            (acceptedFiles) => handleDropChange(acceptedFiles, 'fileGFIP')
                                        }>
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div {...getRootProps()}
                                                     className="dropzone upload-zone dz-clickable">
                                                    <input id={'fileGFIP'} {...getInputProps()} />
                                                    {values.fileGFIP.length === 0 && (
                                                        <div className="dz-message">
                                                            <span className="dz-message-text">Arraste e solte</span>
                                                            <span className="dz-message-or">ou</span>
                                                            <Button type={'button'}
                                                                    color="primary">SELECIONE</Button>
                                                        </div>
                                                    )}
                                                    {values.fileGFIP.map((file) => (
                                                        <div key={file.name}
                                                             className="dz-preview dz-processing dz-image-preview dz-error dz-complete justify-content-center d-flex">
                                                            <div className="dz-image">
                                                                <img src={file.preview} alt="preview"/>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </Col>
                                <Col size={4}>
                                    <label htmlFor={'fileDriveLicense'} className="form-label">
                                        CNH do representante legal<span className={'text-danger'}>*</span>
                                    </label>
                                    <Dropzone
                                        {...dropzoneDefaults}
                                        onDrop={
                                            (acceptedFiles) => handleDropChange(acceptedFiles, 'fileDriveLicense')
                                        }>
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div {...getRootProps()}
                                                     className="dropzone upload-zone dz-clickable">
                                                    <input id={'fileDriveLicense'} {...getInputProps()} />
                                                    {values.fileDriveLicense.length === 0 && (
                                                        <div className="dz-message">
                                                                    <span
                                                                        className="dz-message-text">Arraste e solte</span>
                                                            <span className="dz-message-or">ou</span>
                                                            <Button type={'button'}
                                                                    color="primary">SELECIONE</Button>
                                                        </div>
                                                    )}
                                                    {values.fileDriveLicense.map((file) => (
                                                        <div key={file.name}
                                                             className="dz-preview dz-processing dz-image-preview dz-error dz-complete justify-content-center d-flex">
                                                            <div className="dz-image">
                                                                <img key={file.name}
                                                                     src={file.preview} alt="preview"/>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </form>
            </Content>
        </React.Fragment>
    );
};

export default CompaniesForm;
