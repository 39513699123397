import {useParams} from "react-router-dom";
import {Block} from "../../components/Component"
import {CompanyProvider} from "../../context/CompanyContext";
import CompanyDetails from "./components/CompanyDetails";
import React from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";

const CompanyFormContainer = () => {

    const {companyId} = useParams();
    return (
        <React.Fragment>
            <Head title='Dados de empresa'/>
            <Content>
                <CompanyProvider companyId={companyId}>
                    <Block>
                        <CompanyDetails/>
                    </Block>
                </CompanyProvider>
            </Content>
        </React.Fragment>
    )
}

export default CompanyFormContainer;