import React, {useCallback, useEffect, useRef, useState} from "react";
import {DropdownToggle, DropdownMenu, UncontrolledDropdown, Spinner} from "reactstrap";

import Icon from "../../../../components/icon/Icon";
import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "../../../../hooks/useAuth";
import httpClient from "../../../../utils/HttpClient";
import {toast} from "react-toastify";
import {toastOptions} from "../../../../utils/Presets";
import {parseToDateBR} from "../../../../utils/Commons";

const NotificationItem = (props) => {
    const {icon, iconStyle, text, time, id} = props;
    return (
        <Link key={id} to={`/notifications/details/${id}`}>
            <div className="nk-notification-item" id={id}>
                <div className="nk-notification-icon">
                    <Icon name={icon} className={[`icon-circle ${iconStyle ? " " + iconStyle : ""}`]}/>
                </div>
                <div className="nk-notification-content">
                    <div className="nk-notification-text">{text}</div>
                    <div className="nk-notification-time">{time}</div>
                </div>
            </div>
        </Link>
    );
};

const Notification = () => {
    const {user} = useAuth();
    const {OwnerId} = {...user};
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const intervalRef = useRef(null);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const resp = await httpClient.get('extras/notificacoes', {
                params: {
                    personId: OwnerId,
                    read: false,
                    media: 'inbox'
                }
            });
            const {data} = resp.data;
            setData(data);
        } catch (e) {
            toast.error(e.message, {
                ...toastOptions
            });
        } finally {
            setLoading(false);
        }
    }, [OwnerId]);

    useEffect(() => {
        fetchData().then();
    }, [fetchData]);

    const navigate = useNavigate();

    useEffect(() => {
        //consulta a cada 1min.
        intervalRef.current = setInterval(async () => {
            await fetchData();
        }, 60000);

        return () => clearInterval(intervalRef.current);
    }, [fetchData]);

    if (data.length === 0) return;

    return (
        <UncontrolledDropdown className="user-dropdown">
            <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
                <div className={'icon-status icon-status-info'}>
                    <Icon name="bell"/>
                </div>
            </DropdownToggle>
            <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
                <div className="dropdown-head">
                    <span className="sub-title nk-dropdown-title">Notificações</span>
                    <div className={'text-center p-3'}>
                        {isLoading && (<Spinner size={'sm'}/>)}
                    </div>
                </div>
                <div className="dropdown-body">
                    <div className="nk-notification">
                        {data.length > 0
                            ? data.map((item) => (
                                <NotificationItem
                                    key={item.Id}
                                    id={item.UUID ? item.UUID : item.Id}
                                    icon={'star'}
                                    iconStyle={'bg-success-dim'}
                                    text={item.Title}
                                    time={parseToDateBR(item.SentAt, false, true)}
                                />
                            ))
                            : null
                        }
                    </div>
                </div>
                <div className="dropdown-foot center">
                    <button className={'link'} onClick={() => navigate('/notifications', {replace: true})}>
                        Ver todas
                    </button>
                </div>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default Notification;
