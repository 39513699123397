import {
    Block,
    BlockContent,
    BlockDes,
    BlockHead,
    BlockTitle,
    Button,
    Icon,
    OutlinedInput,
    PreviewCard
} from "../../components/Component";
import {Form, Spinner, Alert} from "reactstrap";
import {Link} from "react-router-dom";
import {useFormik} from "formik";
import {useAuth} from "../../hooks/useAuth";
import React, {useState} from "react";
import Logo from "../../images/logo2x.png";
import LogoDark from "../../images/logo2x.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import * as Yup from 'yup';
import httpClient from "../../utils/HttpClient";

const Login = () => {
    const {login} = useAuth();
    const [isLoading, setLoading] = useState(false);
    const [showPwd, setShowPwd] = useState(false);
    const [errAuth, setErrAuth] = useState('');

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        errors,
        values,
        isValid
    } = useFormik({
        initialValues: {
            login: '',
            password: ''
        },
        validationSchema: Yup.object({
            login: Yup.string().required('Preencha').email('Formato inválido de e-mail'),
            password: Yup.string().required('Preencha')
        }),
        onSubmit: async v => {
            try {
                setLoading(true);
                const res = await httpClient.post(`vendas/usuario/autenticar`, {...v});
                let {data} = {...res.data};
                await login(data);
            } catch (err) {
                if (err.response) {
                    const {errors} = {...err.response.data};
                    Object.entries(errors).forEach(([, value]) => {
                        setErrAuth(value);
                    })
                }
            } finally {
                setLoading(false);
            }
        }
    });

    return <React.Fragment>
        <Head title="Login"/>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
            <div className="brand-logo pb-4 text-center">
                <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
                    <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo"/>
                    <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark"/>
                </Link>
            </div>
            <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
                <BlockHead>
                    <BlockContent>
                        <BlockTitle tag="h4">Portal de Vendas</BlockTitle>
                        <BlockDes>
                            <p>Entre com suas credenciais.</p>
                        </BlockDes>
                    </BlockContent>
                </BlockHead>
                {errAuth && (
                    <div className="mb-3">
                        <Alert color="danger" className="alert-icon">
                            <Icon name="alert-circle"/> {errAuth}
                        </Alert>
                    </div>
                )}
                <Form className="is-alter" onSubmit={handleSubmit}>
                    <div className={'form-group'}>
                        <OutlinedInput type={'email'}
                                       name={'login'}
                                       label={'E-mail'}
                                       size={'xl'}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       value={values.login}
                                       isInvalid={errors.login && touched.login}
                                       error={errors.login}/>
                    </div>
                    <div className={'form-group'}>
                        <OutlinedInput type={showPwd ? 'text' : 'password'}
                                       name={'password'}
                                       label={'Senha'}
                                       size={'xl'}
                                       maxLength={6}
                                       iconRight={
                                           <Button type={'button'}
                                                   onClick={() => setShowPwd(!showPwd)}>
                                               <Icon name={showPwd ? 'eye-off' : 'eye'}/>
                                           </Button>
                                       }
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       value={values.password}
                                       isInvalid={errors.password && touched.password}
                                       error={errors.password}
                        />
                        <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/recover`}>
                            Esqueci minha senha?
                        </Link>
                    </div>
                    <div className="form-group">
                        <Button type="submit"
                                size="lg"
                                className="btn-block"
                                color="primary"
                                disabled={!isValid || isLoading}>
                            {isLoading ? <Spinner size="sm" color="light"/> : "ENTRAR"}
                        </Button>
                    </div>
                </Form>
                <div className="form-note-s2 text-center pt-4">
                    Primeira vez aqui? <Link to={`${process.env.PUBLIC_URL}/register`}>Obter credenciais</Link>
                </div>
            </PreviewCard>
        </Block>
        <AuthFooter/>
    </React.Fragment>;
};
export default Login;
