export const occupationOptionsM = [
    {id: "APOSENTADO_PENSIONISTA", label: "Aposentado/Pensionista", value: 1},
    {id: "AUTONOMO", label: "Autônomo", value: 2},
    {id: "EMPRESARIO", label: "Empresário", value: 3},
    {id: "PROFISSIONAL_LIBERAL", label: "Profissional Liberal", value: 4},
    {id: "ASSALARIADO", label: "Assalariado", value: 5},
    {id: "FUNCIONARIO_PUBLICO", label: "Funcionário Público", value: 6},
    {id: "DESEMPREGADO", label: "Desempregado", value: 7}
];

export const occupationTypeOptionsM = [
    {id: "INSS", label: "INSS", value: 1},
    {id: "SIAPE", label: "SIAPE", value: 2},
    {id: "GOVERNO_ESTADUAL", label: "Governo Estadual", value: 3},
    {id: "PREFEITURA_MUNICIPAL", label: "Prefeitura Municipal", value: 4},
    {id: "FORCAS_ARMADAS", label: "Forças Armadas", value: 5},
    {id: "TRIBUNAL_DE_JUSTICA", label: "Tribunal de Justiça", value: 6},
    {id: "ASSEMBLEIA_LEGISLATIVA", label: "Assembleia Legislativa", value: 7}
]

export const degreeOfRelatedness = [
    'Pai',
    'Mãe',
    'Filho(a)',
    'Enteado(a)',
    'Irmã(o)',
    'Avô(ó)',
    'Tio(a)',
    'Conjuge',
    'Namorado(a)',
    'Outros'
];

export const maritalStatus = [
    'Solteiro(a)',
    'Casado(a)',
    'Divorciado(a)',
    'Separado(a)',
    'Viúvo(a)',
    'Solteiro(a)',
    'Outros'
];

export const genres = [
    'Masculino',
    'Feminino',
    'Outros'
]

export const states = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO'
];

export const fileType = [
    '.pdf',
    '.jpg',
    '.jpeg',
    '.txt',
    '.png'
]

export const companyType = [
    'SA',
    'LTDA',
    'MEI',
    'ME',
    'EIRELI',
];

export const contractStatus = [
    {label: 'Rascunho', color: 'warning', status: 0},
    {label: 'Em vigência', color: 'success', status: 1},
    {label: 'Cancelado', color: 'danger', status: 2},
    {label: 'Aguardando análise', color: 'info', status: 3},
    {label: 'Aguardando ajustes', color: 'warning', status: 4},
    {label: 'Suspenso', color: 'warning', status: 5},
]

export const dueDates = [
    {value: '5', label: 'Todo dia 05'},
    {value: '10', label: 'Todo dia 10'},
    {value: '15', label: 'Todo dia 15'},
    {value: '20', label: 'Todo dia 20'},
    {value: '30', label: 'Todo dia 30'},
]
