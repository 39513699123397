import {
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    BlockDes,
} from "../../components/Component";
import {Card} from "reactstrap";
import {useAuth} from "../../hooks/useAuth";
import {parseToDateBR} from "../../utils/Commons";
import React from "react";
import Head from "../../layout/head/Head";

const Personal = () => {
    const {user} = useAuth();
    const {Profile} = {...user};

    return (
        <React.Fragment>
            <Head title="Informações Pessoais"></Head>
            <Block>
                <BlockHead>
                    <BlockHeadContent>
                        <BlockTitle tag="h5">Informações Pessoais</BlockTitle>
                        <BlockDes>
                            <p>Informações básicas, como nome e contatos utilizados pela plataforma.</p>
                        </BlockDes>
                    </BlockHeadContent>
                </BlockHead>
                <Card className="card-bordered">
                    <div className="nk-data data-list">
                        <div className="data-item">
                            <div className="data-col">
                                <span className="data-label">Nome completo</span>
                                <span className="data-value">{Profile.FullName}</span>
                            </div>
                        </div>
                        <div className="data-item">
                            <div className="data-col">
                                <span className="data-label">CPF</span>
                                <span className="data-value">{Profile.TaxNumber}</span>
                            </div>
                        </div>
                        <div className="data-item">
                            <div className="data-col">
                                <span className="data-label">Data de nascimento</span>
                                <span className="data-value">{parseToDateBR(Profile.Birthday)}</span>
                            </div>
                        </div>
                        <div className="data-item">
                            <div className="data-col">
                                <span className="data-label">Gênero</span>
                                <span className="data-value">{Profile.Gender}</span>
                            </div>
                        </div>
                        <div className="data-item">
                            <div className="data-col">
                                <span className="data-label">E-mail de contato</span>
                                <span className="data-value">{Profile.Email}</span>
                            </div>
                        </div>
                        <div className="data-item">
                            <div className="data-col">
                                <span className="data-label">Telefone de contato</span>
                                <span className="data-value">{Profile.Phone}</span>
                            </div>
                        </div>
                    </div>
                </Card>
            </Block>
        </React.Fragment>
    );
};

export default Personal;
